<template>
    <div class="p-2">
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group label="Текущая система">
                        <div class="d-flex">
                            <system-selector v-model="system_id" class="flex-fill"></system-selector>
                            <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                <b-button :disabled="busy" class="mx-2" variant="primary" @click="refresh_profile"> Где я? </b-button>
                            </b-overlay>
                        </div>
                    </b-form-group>
                    <b-form-group label="Радиус" label-cols label-align="right">
                        <b-input type="number" v-model="range"></b-input>
                    </b-form-group>
                    <b-form-group label="Расстояние" label-cols label-align="right">
                        <b-input type="number" v-model="distance_to_star"></b-input>
                    </b-form-group>
                    <b-form-group label="Тип тела">
                        <b-form-select v-model="subtype">
                            <b-form-select-option :value="null">Не важно</b-form-select-option>
                            <b-form-select-option-group label="Звезда">
                                <b-form-select-option value="Blue-White">Бело-голубая</b-form-select-option>
                                <b-form-select-option value="^F">Белая</b-form-select-option>
                                <b-form-select-option value="White-Yellow">Бело-Желтая</b-form-select-option>
                                <b-form-select-option value="Yellow-Orange">Желто-Оранжевая</b-form-select-option>
                                <b-form-select-option value="Brown">Коричневый карлик</b-form-select-option>
                                <b-form-select-option value="Red">Красный карлик</b-form-select-option>
                                <b-form-select-option value="White Dwarf">Белый карлик</b-form-select-option>
                            </b-form-select-option-group>
                            <b-form-select-option-group label="Планета">
                                <b-form-select-option value="Earth-like">Землеподобная</b-form-select-option>
                                <b-form-select-option value="Water">Водная</b-form-select-option>
                                <b-form-select-option value="Ammonia">Амиачная</b-form-select-option>
                                <b-form-select-option value="High metal">С высоким содержанием металлов</b-form-select-option>
                                <b-form-select-option value="Metal-rich">Богатая металлом</b-form-select-option>
                                <b-form-select-option value="Rocky">Каменная</b-form-select-option>
                                <b-form-select-option value="Icy">Ледяная</b-form-select-option>
                                <b-form-select-option value="Rocky">Каменная</b-form-select-option>
                                <b-form-select-option value="Gas giant">Газовый гигант</b-form-select-option>
                            </b-form-select-option-group>
                        </b-form-select>
                    </b-form-group>
                    <b-form-checkbox v-model="landable" switch>Посадочная</b-form-checkbox>
                    <b-form-group label="Гравитация не менее" label-cols label-align="right">
                        <b-input-group class="mx-2" :prepend="gravity">
                            <b-form-input v-model="gravity" type="range" min="0" max="4" step="0.1"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    
                    
                    <b-form-group label="Сигнал">
                        <b-form-select v-model="body_signal_type" :options="body_signals">
                            <template #first>
                                <b-form-select-option :value="null">Не важно</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col>
                    <b-form-group label="Материалы">
                        <b-form-tags id="tags-component-select" v-model="materials" size="lg" class="mb-2"
                        input-type="number" add-on-change no-outer-focus>
                        <template v-slot="{ tags, disabled, addTag, removeTag }">
                            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                    <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="info">
                                        {{ materialById(tag) }}
                                    </b-form-tag>
                                </li>
                            </ul>
                            <b-dropdown variant="outline-secondary" block menu-class="w-100">
                                <template #button-content>
                                    <b-icon icon="tag-fill"></b-icon> Выбрать материалы
                                </template>
                                <div class="ddcontainer">
                                    <b-dropdown-item-button v-for="option in materials_cat" :key="option.id"
                                    @click="onOptionClick({ option, addTag })">
                                    {{ option.name }}
                                </b-dropdown-item-button>
                            </div>
                        </b-dropdown>
                    </template>
                </b-form-tags>
                <b-form-input v-model="material_content" type="range" min="0" max="100"></b-form-input>
            </b-form-group>
            <b-form-group label="Запас">
                <b-form-select v-model="reserve">
                    <b-form-select-option :value="null">Не важно</b-form-select-option>
                    <b-form-select-option value="Pristine">Нетронутый</b-form-select-option>
                    <b-form-select-option value="Major">Большой</b-form-select-option>
                    <b-form-select-option value="Common">Обычный</b-form-select-option>
                    <b-form-select-option value="Low">Низкий</b-form-select-option>
                    <b-form-select-option value="Depleted">Выработан</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group label="Кольца">
                <b-form-select v-model="ring_type">
                    <b-form-select-option :value="null">Не важно</b-form-select-option>
                    <b-form-select-option value="Icy">Ледяные</b-form-select-option>
                    <b-form-select-option value="Rocky">Каменные</b-form-select-option>
                    <b-form-select-option value="Metallic">Металлические</b-form-select-option>
                    <b-form-select-option value="Metal Rich">Богатые металлом</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group label="Плотность не менее" label-cols label-align="right">
                <b-input type="number" v-model="density"></b-input>
            </b-form-group>
            <b-form-group label="Сигнал">
                <b-form-select v-model="ring_signal_type" :options="ring_signals">
                    <template #first>
                        <b-form-select-option :value="null">Не важно</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
        </b-col>
    </b-row>
    
    <b-button-group>
        <b-button variant="outline-success" @click="doSearch">Найти</b-button>
    </b-button-group>
</b-card>

<b-overlay :show="show_result" rounded="sm">
    <b-table :items="result" :fields="result_fields" bordered head-variant="dark" small striped responsive hover
    :tbody-tr-class="expansRowClass">
    <template #cell(system)="data">
        <b-link :to="`/system/${data.item.system_id}`">{{ data.item.system }}</b-link>
        <span>
            <b-button size="sm" variant="link" class="ml-2 p-0" @click="copyClipboard(data.item.system)">
                <b-icon icon="stickies"></b-icon>
            </b-button>
        </span>
    </template>
    <template #row-details="row">
        <b-card no-body>
            <b-row v-if="row.item.materials">
                <b-col cols="2" class="text-right">Материалы:</b-col>
                <b-col>{{ row.item.materials }}</b-col>
            </b-row>
            <b-row v-if="row.item.signals">
                <b-col cols="2" class="text-right">Сигналы:</b-col>
                <b-col>{{ row.item.signals }}</b-col>
            </b-row>
            <b-table-simple striped small responsive v-if="row.item.body_rings">
                <b-thead head-variant="dark">
                    <b-tr>
                        <b-th>Кольцо</b-th>
                        <b-th>Тип</b-th>
                        <b-th class="text-right">Масса</b-th>
                        <b-th class="text-right">Внутр.радиус</b-th>
                        <b-th class="text-right">Внушн.радиус</b-th>
                        <b-th class="text-right">Плотность</b-th>
                        <b-th>Сигналы</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="ring, idx in row.item.body_rings" :key="idx">
                        <b-td>{{ ring.name }}</b-td>
                        <b-td>{{ ring.type }}</b-td>
                        <b-td class="text-right">{{ ring.mass }}</b-td>
                        <b-td class="text-right">{{ ring.inner }}</b-td>
                        <b-td class="text-right">{{ ring.outer }}</b-td>
                        <b-td class="text-right">{{ ring.density }}</b-td>
                        <b-td>{{ ring.signals }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>
    </template>
</b-table>
</b-overlay>
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import SystemSelector from '@/components/SystemSelector.vue';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'BodySearch',
    inject: ['getUserInfo'],
    components: {
        SystemSelector,
    },
    data() {
        return {
            busy: false,
            show_result: false,
            system_id: null,
            range: 50,
            distance_to_star: 5000,
            landable: false,
            gravity: 0.0,
            subtype: null,
            ring_type: null,
            body_signal_type: null,
            ring_signal_type: null,
            body_signals: [
            { value: 'geological', text: 'Геологический' },
            { value: 'biological', text: 'Биологический' },
            { value: 'human', text: 'Человеки' },
            { value: 'thargoid', text: 'Таргоиды' },
            { value: 'guardian', text: 'Стражи' },
            ],
            ring_signals: [
            { value: 'alexandrite', text: 'Александрит' },
            { value: 'benitoite', text: 'Бенитоит' },
            { value: 'grandidierite', text: 'Грандидьерит' },
            { value: 'monazite', text: 'Монацит' },
            { value: 'musgravite', text: 'Мусгравит' },
            { value: 'rhodplumsite', text: 'Родплумсайт' },
            { value: 'serendibite', text: 'Серендибит' },
            { value: 'opal', text: 'Опал бездны' },
            { value: 'lowtemperaturediamond', text: 'Низкотемпературные алмазы' },
            { value: 'tritium', text: 'Тритий' },
            { value: 'painite', text: 'Пейнит' },
            { value: 'platinum', text: 'Платина' },
            { value: 'bauxite', text: 'Боксит' },
            { value: 'bromellite', text: 'Бромеллит' },
            { value: 'gallite', text: 'Галлит' },
            { value: 'hydrogenperoxide', text: 'Пероксид водорода' },
            { value: 'indite', text: 'Индит' },
            { value: 'lepidolite', text: 'Лепидолит' },
            { value: 'liquidoxygen', text: 'Жидкий кислород' },
            { value: 'methaneclathrate', text: 'Клатрат метана' },
            { value: 'uraninite', text: 'Уранинит' },
            ],
            density: 0,
            material_cat: [],
            material: null,
            material_content: 0,
            materials: [],
            reserve: null,
            consists: {},
            result: [],
            result_fields: [],
            result_fields_maket: [
            { key: 'system', label: 'Система', sortable: true },
            { key: 'name', label: 'Тело' },
            { key: 'subtype', label: 'Тип' },
            { key: 'distance', label: 'Расст', sortable: true,
            class: "text-right", thStyle: {width:"50px"} },
            { key: 'distance_to_star', label: 'Дистанция', sortable: true,
            class: "text-right", thStyle: {width:"50px"} },
            { key: 'reserve', label: 'Запас', sortable: true, thStyle: {width:"100px"}, },
            { key: 'gravity', label: 'Грав', sortable: true, thStyle: {width:"75px"} },
            ],
        }
    },
    computed: {
        criteria() {
            return this.search.trim().toLowerCase()
        },
        materials_filtered() {
            return this.materials_cat.filter(item => {
                return (this.criteria == "" || item.text.toLowerCase().indexOf(this.criteria) > -1);
            });
        },
    },
    async mounted() {
        try {
            let profile = await WS.request('user_profile');
            this.system_id = profile.system_id;
            this.materials_cat = await WS.request('materials_catalog', 'Raw');
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_profile: ${err}`);
        } 
    },
    methods: {
        async refresh_profile() {
            this.busy = true;
            try {
                let profile = await WS.request('profile');
                this.system_id = profile.system_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(`profile: ${err}`);
            }
            this.busy = false;
        },
        async doSearch() {
            try {
                this.show_result = true;
                this.result = await WS.request('search_body',
                { system_id: this.system_id,
                    range: this.range,
                    distance_to_star: this.distance_to_star,
                    subtype: this.subtype,
                    reserve: this.reserve,
                    landable: this.landable,
                    materials: this.materials,
                    material_content: Number(this.material_content),
                    ring_type: this.ring_type,
                    body_signal_type: this.body_signal_type,
                    ring_signal_type: this.ring_signal_type,
                    density: this.density,
                    gravity: Number(this.gravity),
                });
                this.result.forEach((item) => {
                    Vue.set(item,'_showDetails', true);
                })
                this.result_fields = []
                this.result_fields_maket.forEach(item => {
                    this.result_fields.push(item);
                });
                if (this.materials.length > 0) {
                    this.materials.forEach(mat => {
                        this.result_fields.push({ key: mat, label: this.materialById(mat),
                            sortable: true, thStyle: {width:"75px"} });
                            this.result.forEach(item => {
                                item[mat] = item.addition[mat];
                            });
                        });
                    }
                } catch (err) {
                    this.$bvModal.msgBoxOk(`${err}`);
                } 
                this.show_result = false;
            },
            materialById(id) {
                let result = this.materials_cat.find(item => item.id == id)
                return result.name;
            },
            onOptionClick({ option, addTag }) {
                addTag(option.id);
            },
            copyClipboard(value) {
                navigator.clipboard.writeText(value);
            },
            num2text(value) {
                console.log(value);
                if (value === null) return '--';
                return value.toFixed(2) + ' G';
            },
        },
        filters: {
            timestamp(ts) {
                return moment.unix(ts).format("DD.MM.YYYY HH:mm");
            },
            bool2text(value) {
                return value ? 'Да' : 'Нет';
            }
        },
    }
</script>

<style>
/*
Fix an issue in vue-bootstrap v2.22.0:
https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky !important;
}
.maincontainer {
    height: 100vh;
}
.ddcontainer {
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
}
</style>
