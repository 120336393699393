<template>
<div class="m-2">
  <b-card-group columns>
    <b-card header="Преступление">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Штрафов получено</b-td>
            <b-td class="text-right">{{stats.Crime.Fines}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Сумма штрафов</b-td>
            <b-td class="text-right">{{stats.Crime.Total_Fines|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Наград за голову получено</b-td>
            <b-td class="text-right">{{stats.Crime.Bounties_Received}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Всего наград за голову</b-td>
            <b-td class="text-right">{{stats.Crime.Total_Bounties|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Максимальная награда за голову</b-td>
            <b-td class="text-right">{{stats.Crime.Highest_Bounty|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Дурная слава</b-td>
            <b-td class="text-right">{{stats.Crime.Notoriety}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Контрабанда">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Средняя прибыль</b-td>
            <b-td class="text-right">{{stats.Smuggling.Average_Profit|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Ресурсов сбыто</b-td>
            <b-td class="text-right">{{stats.Smuggling.Resources_Smuggled}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Прибыль с черного рынка</b-td>
            <b-td class="text-right">{{stats.Smuggling.Black_Markets_Profits|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Черных рынков</b-td>
            <b-td class="text-right">{{stats.Smuggling.Black_Markets_Traded_With}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Максимальная транзакция</b-td>
            <b-td class="text-right">{{stats.Smuggling.Highest_Single_Transaction|num2text}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Бои">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Боевых контрактов</b-td>
            <b-td class="text-right">{{stats.Combat.Combat_Bonds}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Прибыль с боевых контрактов</b-td>
            <b-td class="text-right">{{stats.Combat.Combat_Bond_Profits|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Получено премий за головы</b-td>
            <b-td class="text-right">{{stats.Combat.Bounties_Claimed}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Прибыль c охоты за головами</b-td>
            <b-td class="text-right">{{stats.Combat.Bounty_Hunting_Profit|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Устранений</b-td>
            <b-td class="text-right">{{stats.Combat.Assassinations}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Прибыль за устранения</b-td>
            <b-td class="text-right">{{stats.Combat.Assassination_Profits|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Уничтожено дронов</b-td>
            <b-td class="text-right">{{stats.Combat.Skimmers_Killed}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Максимальная премия</b-td>
            <b-td class="text-right">{{stats.Combat.Highest_Single_Reward|num2text}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Добыча">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Прибыль с добычи</b-td>
            <b-td class="text-right">{{stats.Mining.Mining_Profits|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Всего добыто</b-td>
            <b-td class="text-right">{{stats.Mining.Quantity_Mined}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Собрано материалов</b-td>
            <b-td class="text-right">{{stats.Mining.Materials_Collected}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Торговля">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Средняя прибыль</b-td>
            <b-td class="text-right">{{stats.Trading.Average_Profit|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Рыночная прибыль</b-td>
            <b-td class="text-right">{{stats.Trading.Market_Profits|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Ресурсов сторговано</b-td>
            <b-td class="text-right">{{stats.Trading.Resources_Traded}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Рынков</b-td>
            <b-td class="text-right">{{stats.Trading.Markets_Traded_With}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Максимальная транзакция</b-td>
            <b-td class="text-right">{{stats.Trading.Highest_Single_Transaction|num2text}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Пассажиры">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Пассажирских миссий принято</b-td>
            <b-td class="text-right">{{stats.Passengers.Passengers_Missions_Accepted}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>VIP пассажиров</b-td>
            <b-td class="text-right">{{stats.Passengers.Passengers_Missions_VIP}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Массовка</b-td>
            <b-td class="text-right">{{stats.Passengers.Passengers_Missions_Bulk}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Всего пассажиров доставлено</b-td>
            <b-td class="text-right">{{stats.Passengers.Passengers_Missions_Delivered}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Недовольных пассажиров</b-td>
            <b-td class="text-right">{{stats.Passengers.Passengers_Missions_Disgruntled}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Выброшено пассажиров</b-td>
            <b-td class="text-right">{{stats.Passengers.Passengers_Missions_Ejected}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Исследования">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>Время в игре</b-td>
            <b-td class="text-right">{{stats.Exploration.Time_Played|duration}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Максимальная выплата</b-td>
            <b-td class="text-right">{{stats.Exploration.Highest_Payout|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Сканирований</b-td>
            <b-td class="text-right">{{stats.Exploration.Efficient_Scans}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Систем посещено</b-td>
            <b-td class="text-right">{{stats.Exploration.Systems_Visited}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Прибыль с исследований</b-td>
            <b-td class="text-right">{{stats.Exploration.Exploration_Profits|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Всего гиперпрыжков</b-td>
            <b-td class="text-right">{{stats.Exploration.Total_Hyperspace_Jumps}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Пройдено в гипере </b-td>
            <b-td class="text-right">{{stats.Exploration.Total_Hyperspace_Distance|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Скан планет 2-го уровня</b-td>
            <b-td class="text-right">{{stats.Exploration.Planets_Scanned_To_Level_2}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Скан планет 3-го уровня</b-td>
            <b-td class="text-right">{{stats.Exploration.Planets_Scanned_To_Level_3}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Самая дальная точка от старта</b-td>
            <b-td class="text-right">{{stats.Exploration.Greatest_Distance_From_Start|num2text}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card header="Траты">
      <b-table-simple hover striped small responsive>
        <b-tbody>
          <b-tr>
            <b-td>На топливо</b-td>
            <b-td class="text-right">{{stats.Bank_Account.Spent_On_Fuel|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>На корабли</b-td>
            <b-td class="text-right">{{stats.Bank_Account.Spent_On_Ships|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>На ремонт</b-td>
            <b-td class="text-right">{{stats.Bank_Account.Spent_On_Repairs|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>На страховки</b-td>
            <b-td class="text-right">{{stats.Bank_Account.Spent_On_Insurance|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>На снаряжение</b-td>
            <b-td class="text-right">{{stats.Bank_Account.Spent_On_Outfitting|num2text}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>На расходники</b-td>
            <b-td class="text-right">{{stats.Bank_Account.Spent_On_Ammo_Consumables|num2text}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </b-card-group>

  <b-form-checkbox size="sm" class="mx-2" v-model="relMode" switch @input="refresh">ежесуточно</b-form-checkbox>
  <b-card>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
  </b-card>
  
</div>
</template>

<script>
import { WS } from '@/misc.js';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

Vue.use(VueApexCharts);
moment.locale('ru');

export default {
    name: 'CmdrStatistics',
    inject: ['getUserInfo'],
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            user_id: null,
            stats: {},
            relMode: false,

            chartSeries: [],
            chartOptions: {
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: [
                    {
                        title: {
                            text: "Прибыль"
                        },
                        labels: {
                            formatter: function (value) {
                                if (value === undefined || value === null) return '--';
                                if (Math.abs(value) >= 1000000000) {
                                    return (value / 1000000000).toFixed(2) + ' млрд.'
                                } else if (Math.abs(value) >= 1000000) {
                                    return (value / 1000000).toFixed(2) + ' млн.'
                                } else if (Math.abs(value) >= 1000) {
                                    return (value / 1000).toFixed(2) + ' тыс.'
                                } else {
                                    return value.toFixed(2);
                                }
                            }
                        },
                    },
                ],
                tooltip: {
                    x: {
                        format: 'dd.MM.yy'
                    },
                },
            },
            
        }
    },
    watch: {
        async $route(to) {
            console.log('CmdrStatistics routed',to)
            if (to.name == 'statistics') {
                if (to.params.user_id) {
                    this.user_id = to.params.user_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.user_id) {
            this.user_id = this.$route.params.user_id;
        }
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.stats = await WS.request('statistics', this.user_id);

                let history = await WS.request(this.relMode?'statistics_history_rel':'statistics_history', 
                                               this.user_id);
                this.chartSeries = [];
                history.forEach((stat) => {
                    this.chartSeries.push({ name: stat.name,
                                            data: stat.data.map(item => ([item.ts*1000, item.value]))
                                          });
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
    },
    filters: {
        num2text(value) {
            if (value === undefined || value === null) return '--';
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return value.toFixed(2);
            }
        },
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        duration(seconds) {
            return moment.duration(seconds,'seconds').humanize();
        },
    },
}
</script>

<style>

</style>
