<template>
<div>
  <b-card>
    <b-row>
      <b-col cols="2">Название:</b-col>
      <b-col>{{fc.name}} ({{fc.callsign}})</b-col>
      <b-col cols="2">Тек.Система:</b-col>
      <b-col>
        <div v-if="fc.system_id">
          <b-link :to="`/system/${fc.system_id}`">{{fc.system}}</b-link>
        </div>
        <div v-else> {{fc.system}} </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">Топливо:</b-col>
      <b-col> {{fc.fuel}} </b-col>
      <b-col cols="2"></b-col>
      <b-col></b-col>
    </b-row>
    <b-row>
      <b-col cols="2">Трюм:</b-col>
      <b-col>
        <b-row>
          <b-col>Товары</b-col>
          <b-col>{{fc.capacity.cargoForSale}}</b-col>
        </b-row>
        <b-row>
          <b-col>Нетоварный груз</b-col>
          <b-col>{{fc.capacity.cargoNotForSale}}</b-col>
        </b-row>
        <b-row>
          <b-col>Резерв</b-col>
          <b-col>{{fc.capacity.cargoSpaceReserved}}</b-col>
        </b-row>
        <b-row>
          <b-col>Экипаж</b-col>
          <b-col>{{fc.capacity.crew}}</b-col>
        </b-row>
        <b-row>
          <b-col>Свободно</b-col>
          <b-col>{{fc.capacity.freeSpace}}</b-col>
        </b-row>
      </b-col>
      <b-col cols="2">Финансы:</b-col>
      <b-col> 
        <b-row>
          <b-col>Баланс</b-col>
          <b-col>{{ fc.finance.bankBalance | num2text }}</b-col>
        </b-row>
        <b-row>
          <b-col>Зарезервировано</b-col>
          <b-col>{{ fc.finance.bankReservedBalance | num2text }}</b-col>
        </b-row>
        <b-row>
          <b-col>Закупочный лимит</b-col>
          <b-col>{{ fc.finance.balanceAllocForPurchaseOrders | num2text }}</b-col>
        </b-row>
        <b-row>
          <b-col>Обслуживание</b-col>
          <b-col>{{ fc.finance.maintenance | num2text }} [ {{ fc.finance.maintenanceToDate | num2text }}]</b-col>
        </b-row>
        <b-row>
          <b-col>Содержание</b-col>
          <b-col>{{ fc.finance.coreCost | num2text }}</b-col>
        </b-row>
        <b-row>
          <b-col>Услуги</b-col>
          <b-col>{{ fc.finance.servicesCost | num2text }} [ {{ fc.finance.servicesCostToDate | num2text }}]</b-col>
        </b-row>
        <b-row>
          <b-col>Прыжки</b-col>
          <b-col>{{ fc.finance.jumpsCost | num2text }}</b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">Обновлен:</b-col>
      <b-col> {{ fc.updated_at | timestamp }} </b-col>
      <b-col cols="2"></b-col>
      <b-col></b-col>
    </b-row>
  </b-card>

  <b-card no-body>
    <b-tabs card>
      <b-tab title="Груз">
        <b-form inline class="m-2">
          <b-input-group prepend="Категория" class="mr-2" size="sm">
            <b-input-group-append>
              <b-form-select v-model="category" :options="categories" size="sm" text-field="name" value-field="name">
                <template #first>
                  <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group-append>
          </b-input-group>
          <b-input-group prepend="Название" class="mx-2" size="sm">
            <b-form-input v-model="search" size="sm"></b-form-input>
          </b-input-group>
        </b-form>
        <b-table :items="cargo_filtered" :fields="cargo_fields" 
                 bordered head-variant="dark" small striped responsive hover>
        </b-table>
      </b-tab>
      <b-tab title="Рынок" >
        <b-form inline class="m-2">
          <b-input-group prepend="Категория" class="mr-2" size="sm">
            <b-input-group-append>
              <b-form-select v-model="category" :options="categories" size="sm" text-field="name" value-field="name">
                <template #first>
                  <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group-append>
          </b-input-group>
          <b-input-group prepend="Название" class="mx-2" size="sm">
            <b-form-input v-model="search" size="sm"></b-form-input>
          </b-input-group>
        </b-form>
        <b-table :items="commodities_filtered" :fields="commodities_fields" 
                 bordered head-variant="dark" small striped responsive hover>
        </b-table>
      </b-tab>
      <b-tab title="Услуги" >
        <b-card-group deck class="flex-wrap">
          <b-card v-for="srv,srvid in fc.services" :key="srvid" class="mb-2"
                  style="min-width: 24rem;max-width: 32rem;">
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">{{srvid | srv2text}}</h6>
                <div v-if="srv.crewMember.enabled == 'NO'" >
                  <h6 class="mb-0"><b-icon icon="pause-circle" variant="danger"></b-icon></h6>
                </div>
              </div>
            </template>
            <b-card-text>
              <b-table-simple striped small responsive>
                <b-tbody>
                  <b-tr>
                    <b-td>Имя</b-td>
                    <b-td class="text-right">{{ srv.crewMember.name }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Зарплата</b-td>
                    <b-td class="text-right">{{ srv.crewMember.salary | num2text }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Цена найма</b-td>
                    <b-td class="text-right">{{ srv.crewMember['hiringPrice'] | num2text }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-tab>
    </b-tabs>
  </b-card>

</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';


export default {
    name: 'FleetCarrier',
    inject: ['getUserInfo'],
    data() {
        return {
            callsign: null,
            fc: {},
            search: '',
            category: null,
            categories: [],
            commodities_fields: [
                { key: 'name', label: 'Название', sortable: true },
                { key: 'category', label: 'Категория', sortable: true },
                { key: 'buy_price', label: 'Покупка', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'sell_price', label: 'Продажа', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'stock', label: 'Предложение', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'demand', label: 'Спрос', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
            ],
            cargo_fields: [
                { key: 'name', label: 'Название', sortable: true },
                { key: 'category', label: 'Категория', sortable: true },
                { key: 'qty', label: 'Количество', sortable: true,
                  class: "text-right", thStyle: {width:"200px"}
                },
                { key: 'minvalue', label: 'мин цена', sortable: true,
                  class: "text-right", thStyle: {width:"200px"}
                },
                { key: 'maxvalue', label: 'макс цена', sortable: true,
                  class: "text-right", thStyle: {width:"200px"}
                },
                { key: 'stolen', label: 'Украден', thStyle: {width:"100px"} },
            ],
        }
    },
    computed: {
        cargo_filtered() {
            return this.fc.cargo.filter(item => {
                return ((this.category === null || (item.category == this.category)) &&
                        (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1));
            });
        },
        commodities_filtered() {
            return this.fc.commodities.filter(item => {
                return ((this.category === null || (item.category == this.category)) &&
                        (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1));
            });
        },
    },
    watch: {
        async $route(to) {
            console.log('FleetCarrier routed',to)
            if (to.name == 'fleetcarrier') {
                if (to.params.callsign) {
                    this.callsign = to.params.callsign;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.callsign) {
            this.callsign = this.$route.params.callsign;
        }
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.categories = await WS.request('commodity_categories');
                this.fc = await WS.request('fc', this.callsign);
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
    },
    filters: {
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        num2text(value) {
            if (value === undefined || value === null) return '--';
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return value.toFixed(2);
            }
        },
        srv2text(value) {
          if (value == "refuel") return "Заправка";
          if (value == "repair") return "Ремонт";
          if (value == "rearm") return "Перезарядка";
          if (value == "voucherredemption") return "Обналичка";
          if (value == "blackmarket") return "Черный рынок";
          return value;
        }
    },
}
</script>

<style>

</style>
