<template>
<div class="p-2">
  <b-row>
    <b-col cols=6>
      <b-form-group label="Система" label-cols="auto" label-align="right">
        <div class="d-flex">
          <system-selector v-model="system_id" @input="refresh" class="flex-fill"></system-selector>
          <b-overlay :show="busy1" rounded opacity="0.6" spinner-small spinner-variant="primary"
                     class="d-inline-block">
            <b-button :disabled="busy1" class="mx-2" variant="primary" @click="refresh_profile">
              Где я?
            </b-button>
          </b-overlay>
        </div>
      </b-form-group>
      <b-form-group label="Станция" label-cols="auto" label-align="right">
        <b-form-select v-model="station_id" v-bind:options="stations" text-field="name" value-field="id">
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
                   class="d-inline-block">
          <b-button-group>
            <b-button variant="outline-success" @click="doSearch('forward')">Отвезти</b-button>
            <b-button variant="outline-danger" @click="doSearch('backward')">Привезти</b-button>
          </b-button-group>
        </b-overlay>
      </b-form-group>
    </b-col>
    
    <b-col>
      <b-form-group label="Радиус" label-cols label-align="right">
        <b-input type="number" v-model="range"></b-input>
      </b-form-group>
      <b-form-group label="Расстояние" label-cols label-align="right">
        <b-input type="number" v-model="distance_to_star"></b-input>
      </b-form-group>
      <b-form-checkbox v-model="faction_owned" switch> Только фракционные</b-form-checkbox>
      <b-form-checkbox v-model="planetary" switch> Разрешить планетарные</b-form-checkbox>
      <b-form-checkbox v-model="large_pad" switch> Большая площадка</b-form-checkbox>
    </b-col>
    
    <b-col>
      <b-form-group label="Предложение" label-cols label-align="right">
        <b-input type="number" v-model="min_stock"></b-input>
      </b-form-group>
      <b-form-group label="Спрос" label-cols label-align="right">
        <b-input type="number" v-model="min_demand"></b-input>
      </b-form-group>
      <b-form-group label="Профит" label-cols label-align="right">
        <b-input type="number" v-model="min_profit"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  
    <b-table :items="result" :fields="result_fields"
             head-variant="dark" small striped responsive hover bordered>
      <template #cell(system)="data">
        <b-link :to="`/system/${data.item.system_id}`">{{data.item.system}}</b-link>
      </template>      
      <template #cell(station)="data">
        <b-link :to="`/station/${data.item.station_id}`">{{data.item.station}}</b-link>
      </template>      
    </b-table>
  
</div>
</template>


<script>
import { WS } from '@/misc.js';
import SystemSelector from '@/components/SystemSelector.vue';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'TradeS2M',
    components: {
        SystemSelector,
    },
    data() {
        return {
            busy: false,
            busy1: false,
            range: 50,
            distance_to_star: 5000,
            min_stock: 5000,
            min_demand: 5000,
            min_profit: 1000,
            faction_owned: false,
            planetary: true,
            large_pad: false,
            station_id: null,
            system_id: null,
            nearest_systems: [],
            stations: [],
            result: [],
            result_fields: [
                { key: 'system', label: 'Система', sortable: true },
                { key: 'station', label: 'Станция', sortable: true },
                { key: 'name', label: 'Товар', sortable: true },
                { key: 'category', label: 'Категория', sortable: true },
                { key: 'buy_price', label: 'Покупка', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'sell_price', label: 'Продажа', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'mean_price', label: 'Средняя',
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'diff', label: 'Профит', sortable: true,
                  class: "text-right", thStyle: {width:"100px"},
                },
                { key: 'stock', label: 'Предложение', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'demand', label: 'Спрос', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'updated', label: 'Обновлено', sortable: true,
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
        }
    },
    async mounted() {
        try {
            let profile = await WS.request('user_profile');
            this.system_id = profile.system_id;
            this.station_id = profile.starport_id;
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_profile: ${err}`);
        } 
        await this.refresh();
    },
    methods: {
        async refresh_profile() {
            this.busy1 = true;
            try {
                let profile = await WS.request('profile');
                this.system_id = profile.system_id;
                this.station_id = profile.starport_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(`profile: ${err}`);
            }
            this.busy1 = false;
            await this.refresh();
        },
        async refresh() {
            try {
                this.stations = await WS.request("system_stations", this.system_id);
                if (this.station_id) {
                    if (this.stations.findIndex(item => item.id == this.station_id) < 0) {
                        this.station_id = null;
                    }
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            } 
        },
        async doSearch(direction) {
            try {
                this.busy = true;
                this.result = await WS.request(`trade_s2m_${direction}`,
                                               { station_id: this.station_id,
                                                 range: Number(this.range),
                                                 distance_to_star: this.distance_to_star,
                                                 min_stock: Number(this.min_stock),
                                                 min_demand: Number(this.min_demand),
                                                 min_profit: Number(this.min_profit),
                                                 faction_owned: this.faction_owned,
                                                 planetary: this.planetary,
                                                 large_pad: this.large_pad,
                                               });
                this.busy = false;
            } catch (err) {
                this.$bvModal.msgBoxOk(`doSearch: ${err}`);
            } 
        },
    },
    
        

}
</script>

<style>

</style>
