<template>
<div>
  <b-card>
    <b-row>
      <b-col>
        <b-form-group label="Население не меньше">
          <b-input type="number" v-model="filter.population"></b-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Первичная Экономика">
          <b-form-select v-model="filter.primary_economy" v-bind:options="economies">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Сила">
          <b-form-select v-model="filter.power" v-bind:options="powers">
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="filter.hole" switch> разрешить дырки </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Станция">
          <b-form-select v-model="filter.station" v-bind:options="stations">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Экономика Станции">
          <b-form-select v-model="filter.station_economy" v-bind:options="economies">
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
               class="d-inline-block" @hidden="onHidden">
      <b-button ref="button" :disabled="busy" class="mx-2" squared variant="primary"
                @click=doSearch>
        Поиск
      </b-button>
    </b-overlay>
  </b-card>
  
  <b-table :items="expans" :fields="expans_fields" 
           bordered head-variant="dark" small striped responsive hover >
    <template #cell(source)="data">
      <b-link :to="`/system/${data.item.src_id}`">{{data.item.source}}</b-link>
    </template>      
    <template #cell(destination)="data">
      <b-link :to="`/system/${data.item.dst_id}`">{{data.item.destination}}</b-link>
    </template>      
  </b-table>
</div>
</template>

<script>
import { WS } from '@/misc.js';

export default {
    name: 'SearchExpansion',
    inject: ['getUserInfo'],
    components: {
    },
    data() {
        return {
            busy: false,
            faction_id: null,
            filter: {hole: 'false', station_economy: '', station: '', power: '',
                     primary_economy: '', population: '1000000'},
            economies: [{value: '', text: 'не важно'},
                        {value: 'Agriculture', text: 'Сельское хозяйство'},
                        {value: 'HighTech', text: 'Высокие технологии '},
                        {value: 'Industrial', text: 'Промышленная'},
                        {value: 'Extraction', text: 'Добыча'},
                        {value: 'Refinery', text: 'Переработка'},
                        {value: 'Tourism', text: 'Туризм'},
                        {value: 'Military', text: 'Военная'},
                        {value: 'Service', text: 'Услуги'},
                        {value: 'Colony', text: 'Колония'},
                       ],
            stations: [{value:'',text:'не важно'},
                       {value:'bigspace',text:'Большая космическая станция'},
                       {value:'big',text:'Любая с большой площадкой'},
                      ],
            powers: ['Aisling Duval','Archon Delaine','Arissa Lavigny-Duval','Denton Patreus','Edmund Mahon','Felicia Winters','Pranav Antal','Li Yong-Rui','Yuri Grom','Zachary Hudson','Zemina Torval',
                     {value:'None',text:'Независимая'},
                     {value:'',text:'не важно'}],
            
            expans: [],
            expans_fields: [
                { key: 'source', label: 'Исход', sortable: true, thStyle: {width:"250px"} },
                { key: 'destination', label: 'Цель', sortable: true, thStyle: {width:"250px"} },
                { key: 'faction', label: 'Контр.фракция', thStyle: {width:"400px"} },
                { key: 'population', label: 'Население', sortable: true, class: "text-right",
                  formatter: value => {
                      // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
                      if (value >= 1000000000) {
                          return (value / 1000000000).toFixed(2) + ' млрд.'
                      } else if (value >= 1000000) {
                          return (value / 1000000).toFixed(2) + ' млн.'
                      } else if (value >= 1000) {
                          return (value / 1000).toFixed(2) + ' тыс.'
                      } else {
                          return (value === null) ? '--' : value.toFixed(2);
                      }
                  }},
                { key: 'economy', label: 'Экономика', sortable: true },
                { key: 'power', label: 'Сила', sortable: true },
                { key: 'control_influence', label: 'Вл.', sortable: true, class: "text-right",
                  thStyle: {width:"70px"},
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }},
                { key: 'outer_influence', label: 'Вл.', sortable: true, class: "text-right",
                  thStyle: {width:"70px"},
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }},
                { key: 'factions', label: 'Фр', sortable: true, class: "text-right", thStyle: {width:"70px"} },
                { key: 'stations', label: 'Ст', sortable: true, class: "text-right", thStyle: {width:"70px"} },
                { key: 'distance', label: 'Раст', sortable: true, class: "text-right",
                  thStyle: {width:"100px"},
                  formatter: value => {
                      return value.toFixed(2)
                  }},
            ],
            
        }
    },
    methods: {
        async doSearch() {
            this.busy = true
            let faction_id = this.getUserInfo().faction_id;
            try {
                this.expans = await WS.request('search_expansion', { faction_id, filter: this.filter });
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
            this.busy = false
        },
    },
}
</script>

<style>
.exptable {
    /* overflow-y: scroll; */
    /* max-height: 90vh; */
    max-height: calc(100vh - 500px);;
}
.mychart {
    width: calc(100% - 50px);
}
</style>
