<template>
<div class="p-2">
  <b-form inline class="my-2">
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
  </b-form>
  
  <b-tabs card>
    <b-tab :title="name" v-for="name,type,idx in maket" :key="idx">
      <b-card-group deck class="flex-wrap">
        <b-card no-body v-for="mod,idx in modules[type]" :key="idx"
                class="mb-2 p-1" style="min-width: 500px;max-width: 800px;">
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">{{mod.module}}</h5>
              <span class="float-right" v-if="mod.hard">{{mod.hard}} - {{mod.size}}</span>
            </div>
          </template>
          <b-table-simple small responsive caption-top>
            <caption>Материалы</caption>
            <b-tbody >
              <b-tr v-for="mat in mod.components" :key="mat.name"
                    :variant="(mat.quantity<=mat.avail) ? 'success' : 'danger'">
                <b-td>{{ mat.name }}</b-td>
                <b-td>{{ mat.rarity }}</b-td>
                <b-td>{{ mat.quantity }}</b-td>
                <b-td>({{ mat.avail }})</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple small responsive caption-top v-if="mod.commodities">
            <caption>Товары</caption>
            <b-tbody >
              <b-tr v-for="mat in mod.commodities" :key="mat.name">
                <b-td>{{ mat.name }}</b-td>
                <b-td>{{ mat.quantity }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-card-group>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { WS } from '@/misc.js';

export default {
    name: 'TechBroker',
    inject: ['getUserInfo'],
    components: {
    },
    data() {
        return {
            user_id: this.getUserInfo().id,
            users: [],
            modules: [],
            maket: {
                'human-module': 'Модули Людей',
                'human-weapon': 'Оружие Людей',
                'guardian-module': 'Модули Стражей',
                'guardian-weapon': 'Оружие Стражей',
                'guardian-vessel': 'Истребители Стражей',
            }
        }
    },
    watch: {
        async $route(to) {
            console.log('TechoBroker routed',to);
            if (to.name == 'techbroker') {
                await this.refresh();
            }
        },
    },
    async mounted() {
        this.users = await WS.request('users');
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.modules = await WS.request('techbroker', { user_id: this.user_id });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
    },    
}
</script>

<style>
</style>
