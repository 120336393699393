<template>
<div class="m-1">
  <b-form inline class="my-2">
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
  </b-form>

  <b-row>
    <b-col>
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1" v-for="blueprint in blueprints" :key="blueprint.alias">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`accordion-${blueprint.alias}`"
                      :variant="blueprint.secured ? 'success' : 'secondary'">
              {{ blueprint.name }}
              <b-icon class="float-right" v-if="blueprint.secured" icon="hand-thumbs-up"></b-icon>
            </b-button>
          </b-card-header>
          <b-collapse :id="`accordion-${blueprint.alias}`" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="d-flex justify-content-between align-items-center">
                {{ blueprint.description }}
                <b-form-checkbox switch size="sm" v-model="blueprint.secured"
                                 @change="blueprint_secure(blueprint)">
                  Закреплен
                </b-form-checkbox>
              </div>
              <b-card no-body v-for="item in blueprint.grades" :key="item.grade" class="mb-1">
                <b-card-header>
                  Грейд: {{ item.grade }}
                </b-card-header>
                <b-card-text class="m-1">
                  <b-row>
                    <b-col>
                      <b-table-simple small responsive caption-top>
                        <caption>Модификаторы</caption>
                        <b-tbody >
                          <b-tr v-for="ft in item.features" :key="ft.name"
                                :variant="((ft.up_is_good && ft.hi > 0) || (!ft.up_is_good && ft.hi < 0)) ? 'success' : 'danger'">
                            <b-td>{{ ft.name }}</b-td>
                            <b-td>{{ (ft.hi>0?'+':'')+(ft.hi*100).toFixed(0)+'%' }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                    <b-col>
                      <b-table-simple small responsive caption-top>
                        <caption>Стоимость</caption>
                        <b-tbody >
                          <b-tr v-for="mat in item.components" :key="mat.name"
                                :variant="(mat.quantity<=mat.avail) ? 'success' : 'danger'">
                            <b-td>{{ mat.name }}</b-td>
                            <b-td>{{ mat.quantity }}</b-td>
                            <b-td>({{ mat.avail }})</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-col>
    <b-col>
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1" v-for="expeffect in expeffects" :key="expeffect.alias">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`expeffect-${expeffect.alias}`" variant="secondary">
              {{ expeffect.name }}
            </b-button>
          </b-card-header>
          <b-collapse :id="`expeffect-${expeffect.alias}`" accordion="expeffect-accordion" role="tabpanel">
            <b-card-body>
              {{ expeffect.description }}
              <b-table-simple small responsive caption-top>
                <caption>Модификаторы</caption>
                <b-tbody >
                  <b-tr v-for="ft in expeffect.features" :key="ft.name"
                        :variant="((ft.value === null) || (ft.up_is_good && ft.value > 0) || (!ft.up_is_good && ft.value < 0)) ? 'success' : 'danger'">
                    <b-td>{{ ft.name }}</b-td>
                    <b-td v-if="ft.value">{{ (ft.value>0?'+':'')+ft.value+'%' }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-table-simple small responsive caption-top>
                <caption>Стоимость</caption>
                <b-tbody >
                  <b-tr v-for="mat in expeffect.components" :key="mat.name"
                        :variant="(mat.quantity<=mat.avail) ? 'success' : 'danger'">
                    <b-td>{{ mat.name }}</b-td>
                    <b-td>{{ mat.quantity }}</b-td>
                    <b-td>({{ mat.avail }})</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-col>
  </b-row>

</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'ModuleBlueprint',
    inject: ['getUserInfo'],
    data() {
        return {
            blueprints: [],
            expeffects: [],
            module: null,
            users: [],
            user_id: this.getUserInfo().id,
            engineer_id: null,
        }
    },
    watch: {
        async $route(to) {
            console.log('ModuleBlueprint routed',to);
            if (to.name == 'moduleblueprint') {
                if (to.params.module) {
                    this.module = to.params.module;
                }
                if (to.params.engineer) {
                    this.engineer_id = to.params.engineer;
                }
                if (to.params.user) {
                    this.user_id = to.params.user;
                }
                await this.refresh();
            }
        },
    },
    async mounted() {
        if (this.$route.params.module) {
            this.module = this.$route.params.module;
        }
        if (this.$route.params.engineer) {
            this.engineer_id = this.$route.params.engineer;
        }
        if (this.$route.params.user) {
            this.user_id = this.$route.params.user;
        }
        this.users = await WS.request('users');
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.blueprints = await WS.request('blueprints',
                                                   { module: this.module, user_id: this.user_id,
                                                     engineer_id: this.engineer_id });
                this.expeffects = await WS.request('expeffects',
                                                   { module: this.module, user_id: this.user_id,
                                                     engineer_id: this.engineer_id });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        async blueprint_secure(blueprint) {
            try {
                this.blueprints.forEach((item) => {
                    item.secure = false;
                });
                blueprint.secure = await WS.request('blueprint_secure',
                                                   { user_id: this.user_id, engineer_id: this.engineer_id,
                                                     blueprint: blueprint.alias, module: this.module });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
    },
    filters: {
    },
}
</script>

<style>
.cmdrcard {
    width: 300px;
}
</style>
