<template>
<div>
    <b-card title="Пилот">
        <b-form-group label="Имя">
            <b-form-input v-model="cmdr.name"></b-form-input>
        </b-form-group>
        <b-form-row>
            <b-col>
                <b-form-group label="Логин">
                    <b-form-input type="text" autocomplete="off" v-model="cmdr.login"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Пароль">
                    <b-form-input type="password" autocomplete="new-password" v-model="cmdr.password"></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-button squared variant="success" @click="saveData">Сохранить</b-button>
    </b-card>

    <b-card title="Эскадрилья">
        <b-card>
            <b-form-row>
                <b-col>
                    <b-form-group label="Название">
                        <b-form-input v-model="squad.name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Фракция">
                        <faction-selector v-model="squad.faction_id" nofavor></faction-selector>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-button @click="saveSquad" variant="success" >Сохранить</b-button>
        </b-card>

        <b-form-group label="Состав">
            <b-list-group horizontal>
                <b-list-group-item v-for="pilot_name,pilot_id in squad.pilots" :key="pilot_id"
                                    class="d-flex justify-content-between align-items-center">
                    {{ pilot_name }} 
                    <b-button @click="squadExcludePilot(pilot_id)" class="mx-2" variant="outline-danger"
                                v-b-tooltip.hover.ds500 title="Исключить из эскадрильи">
                        <b-icon icon="person-dash"></b-icon>
                    </b-button>
                </b-list-group-item>
            </b-list-group>
        </b-form-group>

        <b-form-group label="Заявки">
            <b-list-group horizontal>
                <b-list-group-item v-for="plt in squad.requests" :key="plt.id"
                                    class="d-flex justify-content-between align-items-center">
                    {{ plt.name }} 
                    <b-button @click="squadIncludePilot(plt.id)" class="mx-2" variant="outline-primary"
                                v-b-tooltip.hover.ds500 title="Включить в эскадрилью">
                        <b-icon icon="person-plus"></b-icon>
                    </b-button>
                </b-list-group-item>
            </b-list-group>
        </b-form-group>

        <b-form-group label="Присоединиться к эскадрилье">
            <b-input-group>
                <b-form-input v-model="joinsquad"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-success" :disabled="! joinsquad" 
                              @click="squadJoin">Запросить</b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </b-card>
</div>
</template>

<script>
import { WS } from '@/misc.js';

import FactionSelector from '@/components/FactionSelector.vue';

export default {
    name: 'CmdrSettings',
    inject: ['getUserInfo'],
    components: {
        FactionSelector
    },
    data() {
        return {
            cmdr: null,
            squad: null,
            joinsquad: null,
        }
    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.cmdr = await WS.request('user_data');
                this.squad = await WS.request('squad_data');
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        async saveData() {
            try {
                await WS.request('user_update', this.cmdr);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },
        async saveSquad() {
            try {
                await WS.request('squad_update', { name: this.squad.name });
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },
        async squadExcludePilot(pilot_id) {
            try {
                let yesNo = await this.$bvModal.msgBoxConfirm('Исключить пилота из эскадрильи?', 
                                                              { okTitle:"Да", cancelTitle:"Нет" });
                if (yesNo) {
                    await WS.request('squad_exclude', { pilot: pilot_id });
                    this.squad = await WS.request('squad_data');
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },
        async squadIncludePilot(pilot_id) {
            try {
                let yesNo = await this.$bvModal.msgBoxConfirm('Принять пилота в эскадрилью?', 
                                                              { okTitle:"Да", cancelTitle:"Нет" });
                if (yesNo) {
                    await WS.request('squad_include', { pilot: pilot_id });
                    this.squad = await WS.request('squad_data');
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },
        async squadJoin() {
            try {
                await WS.request('squad_join', { squadname: this.joinsquad });
                this.joinsquad = null;
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },
    }
}
</script>

<style>

</style>
