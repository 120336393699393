export const backend_URI = '/api'
// export const backend_URI = 'https://wgis.molotex.ru/api'
// export const backend_URI = 'http://test.molotex.ru:7956/api'
// export const backend_URI = 'http://localhost:8898/api'

export let WS = {
    async login(username, password)  {
        let response = await fetch(backend_URI+'/login',
                                   {
                                       method: 'POST',
                                       credentials: 'include',
                                       body: JSON.stringify({username,password})
                                   });
        if (!response.ok) {
            throw new Error(await response.text());
        } else {
            return await response.json();
        }
    },
    async logout()  {
        let response = await fetch(backend_URI+'/logout', { credentials: 'include' });
        if (!response.ok) {
            throw new Error(await response.text());
        } else {
            return await response.json();
        }
    },
    async request(funcname, param) {
        let url = backend_URI + '/' + funcname;
        let options = { credentials: 'include' }
        if (param !== undefined) {
            if (typeof(param) == 'object') {
                options['method'] = 'POST'
                options['body'] = JSON.stringify(param)
                // for (let key in param) {
                //     url += `?${key}=${param[key]}`;
                // }
            } else {
                url += '/'+param;
            }
        }
        let response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(await response.text());
        } else {
            return await response.json();
        }
    },
};
