<template>
<div class="m-1">
  <b-form inline class="my-2">
    <b-input-group prepend="Подсистема" size="sm">
      <b-input-group-append>
        <b-form-select v-model="ship_module" :options="modules" size="sm" @input="refresh">
          <template #first>
            <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Статус" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="status" size="sm" @input="refresh">
          <b-form-select-option value="unlocked">Разлочен</b-form-select-option>
          <b-form-select-option value="invited">Приглашен</b-form-select-option>
          <b-form-select-option value="known">Открыт</b-form-select-option>
          <b-form-select-option value="unknown">Закрыт</b-form-select-option>
          <b-form-select-option value="any">Любой</b-form-select-option>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
  </b-form>
  
  <b-card-group deck class="flex-wrap">
    <b-card no-body v-for="engineer in engineers" :key="engineer.id"
            class="mb-2" style="min-width: 500px;max-width: 800px;">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">{{ engineer.name }}</h5>
          <div v-if="engineer.progress">
            <span>{{ engineer.progress.status }}</span>
            <span v-if="engineer.progress.rank">
              Ранг: {{ engineer.progress.rank }}
            </span>
            <span v-if="engineer.progress.progress">
              Прогресс: {{ engineer.progress.progress }}
            </span>
          </div>
        </div>
      </template>
      
      <b-card-text class="m-1">
        <b-media>
          <template #aside>
              <img :src="`/icons/engineer-${engineer.id}.png`" width="150">
          </template>
          <p>
            Система: <b-link :to="`/system/${engineer.system_id}`">{{ engineer.system }}</b-link>
            <span v-if="engineer.distance">
              ({{ engineer.distance.distance }} с.л. до {{ engineer.distance.system }})
            </span>
          </p>
          <p>База: <b-link :to="`/station/${engineer.base_id}`">{{ engineer.base }}</b-link></p>
          <p>Наводка: {{ engineer.reference }}</p>
          <p>Приглашение: {{ engineer.invite }}</p>
          <p>Разлочка: {{ engineer.unlock }}</p>
        </b-media>
        
        <b-table-simple striped small responsive hover>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Подсистема</b-th>
              <b-th>Уровень</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="sys,idx in engineer.blueprints" :key="idx" :variant="sys.secured ? 'success' : null">
              <b-td>
                <b-link :to="`/blueprint/${sys.alias}/${engineer.id}/${user_id}`">{{ sys.name }}</b-link>
                <!-- <b-icon class="float-right" v-if="sys.secured" icon="hand-thumbs-up"></b-icon> -->
              </b-td>
              <b-td>{{ sys.grade }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-text>
    </b-card>
  </b-card-group>
</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'EDEngineers',
    inject: ['getUserInfo'],
    data() {
        return {
            engineers: [],
            ship_module: null,
            modules: [],
            users: [],
            user_id: this.getUserInfo().id,
            status: 'any',
        }
    },
    watch: {
        async $route(to) {
            console.log('EDEngineers routed',to);
            if (to.name == 'engineers') {
                await this.refresh();
            }
        },
    },
    async mounted() {
        this.modules = await WS.request('blueprint_modules');
        this.users = await WS.request('users');
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.engineers = await WS.request('engineers', { module: this.ship_module, user_id: this.user_id, status: this.status });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        }
    },
    filters: {
    },
}
</script>

<style>
.cmdrcard {
    width: 300px;
}
</style>
