<template>
<div class="p-2">
  <b-card>
    <b-row>
      <b-col>
        <b-form-group label="Текущая система">
          <div class="d-flex">
            <system-selector v-model="system_id" class="flex-fill"></system-selector>
            <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
                       class="d-inline-block">
              <b-button :disabled="busy" class="mx-2" variant="primary" @click="refresh_profile">
                Где я?
              </b-button>
            </b-overlay>
          </div>
        </b-form-group>
        
        <b-form-group label="Радиус" label-cols label-align="right">
          <b-input type="number" v-model="range"></b-input>
        </b-form-group>
        <b-form-group label="Расстояние" label-cols label-align="right">
          <b-input type="number" v-model="distance_to_star"></b-input>
        </b-form-group>
        <b-form-checkbox v-model="planetary" switch> Планетарные</b-form-checkbox>
        <b-form-checkbox v-model="large_pad" switch> Большая площадка</b-form-checkbox>
      </b-col>
      <b-col cols=8>
        <b-form-group>
          <b-form-tags id="tags-component-select" v-model="module" size="lg" class="mb-2"
                       input-type="number" add-on-change no-outer-focus>
            <template v-slot="{ tags, disabled, addTag, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="info">
                    <!-- {{ tag }} -->
                    {{ moduleById(tag) }}
                  </b-form-tag>
                </li>
              </ul>
              <b-row>
                <b-col cols="auto">
                  <b-form-group >
                    <b-form-select v-model="category" v-bind:options="categories">
                      <b-form-select-option-group label="Корабельные модули">
                        <b-form-select-option value="hardpoint">Оружейный отсек</b-form-select-option>
                        <b-form-select-option value="utility">Внешний подвес</b-form-select-option>
                        <b-form-select-option value="standard">Основной отсек</b-form-select-option>
                        <b-form-select-option value="internal">Дополнительный отсек</b-form-select-option>
                      </b-form-select-option-group>
                      <b-form-select-option value="ships">Корабли</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-dropdown variant="outline-secondary" block menu-class="w-100">
                    <template #button-content>
                      <b-icon icon="tag-fill"></b-icon> Выбрать товар
                    </template>
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                      <b-form-group label="Поиск" label-for="tag-search-input" label-cols-md="auto"
                                    class="mb-0" label-size="sm" :disabled="disabled">
                        <b-form-input v-model="search" id="tag-search-input" type="search"
                                      size="sm" autocomplete="off"></b-form-input>
                      </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="ddcontainer">
                      <b-dropdown-item-button v-for="option in modules_filtered" :key="option.value"
                                              @click="onOptionClick({ option, addTag })">
                        {{ option.text }}
                      </b-dropdown-item-button>
                      <b-dropdown-text v-if="modules_filtered.length === 0">
                        Список пуст
                      </b-dropdown-text>
                    </div>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
          </b-form-tags>
        </b-form-group>
        <b-button-group>
          <b-button variant="outline-success" @click="doSearch">Найти</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
  
  <b-table :items="result" :fields="result_fields" 
           bordered head-variant="dark" small striped responsive hover 
           :tbody-tr-class="expansRowClass">
    <template #cell(system)="data">
      <b-link :to="`/system/${data.item.system_id}`">{{data.item.system}}</b-link>
    </template>
    <template #cell(station)="data">
      <b-link :to="`/station/${data.item.station_id}`">{{data.item.station}}</b-link>
      <div>
        <small class="float-right text-muted">{{data.item.faction}}</small>
      </div>
    </template>
    <template #cell(landing_pad)="data">
      {{data.item.landing_pad}}
      <div v-if="data.item.is_planetary">
        <small class="float-right text-muted">Планетарка</small>
      </div>
    </template>
  </b-table>
  
</div>
</template>

<script>
import { WS } from '@/misc.js';
import SystemSelector from '@/components/SystemSelector.vue';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'ModuleSearch',
    inject: ['getUserInfo'],
    components: {
        SystemSelector,
    },
    data() {
        return {
            busy: false,
            system_id: null,
            search: '',
            search_mode: 'buy',
            search_options: [{ text: 'Купить', value: 'buy' },
                             { text: 'Продать', value: 'sell' }],
            category: 'ships',
            module: [],
            modules: [],
            planetary: true,
            large_pad: false,
            range: 50,
            distance_to_star: 5000,
            result: [],
            result_fields: [
                { key: 'system', label: 'Система', sortable: true },
                { key: 'distance', label: 'Расст', sortable: true,
                  class: "text-right", thStyle: {width:"50px"} },
                { key: 'station', label: 'Станция' },
                { key: 'distance_to_star', label: 'Дистанция', sortable: true,
                  class: "text-right", thStyle: {width:"50px"} },
                { key: 'landing_pad', label: 'Площадка' },
                { key: 'module', label: 'Модуль' },
                { key: 'price', label: 'Цена', sortable: true,
                  class: "text-right", thStyle: {width:"100px"} },
                { key: 'updated', label: 'Обновлено', sortable: true,
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
        }
    },
    computed: {
        criteria() {
            return this.search.trim().toLowerCase()
        },
        modules_filtered() {
            return this.modules.filter(item => {
                return ((item.category == this.category) &&
                        (this.criteria == "" || item.text.toLowerCase().indexOf(this.criteria) > -1 ));
            });
        },
    },
    async mounted() {
        try {
            let profile = await WS.request('user_profile');
            this.system_id = profile.system_id;
            this.modules = await WS.request('outfitting_and_shipyard');
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_profile: ${err}`);
        } 
    },
    methods: {
        async refresh_profile() {
            this.busy = true;
            try {
                let profile = await WS.request('profile');
                this.system_id = profile.system_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(`profile: ${err}`);
            }
            this.busy = false;
        },
        async doSearch() {
            try {
                this.result = await WS.request('search_outfitting',
                                               { system_id: this.system_id,
                                                 module: this.module.map(item => Number(item)),
                                                 planetary: this.planetary,
                                                 large_pad: this.large_pad,
                                                 range: this.range,
                                                 distance_to_star: this.distance_to_star
                                               });
                console.log(this.result);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        moduleById(id) {
            let result = this.modules.find(item => item.value == id)
            return result.text;
        },
        onOptionClick({ option, addTag }) {
            addTag(option.value)
            this.search = ''
        },
    },
    filters: {
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        bool2text(value) {
            return value ? 'Да' : 'Нет';
        }
    },
}
</script>

<style>
/*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.maincontainer {
    height: 100vh;
}
.ddcontainer {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
}
</style>
