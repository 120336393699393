<template>
<div>
  <b-form inline>
    <b-form-datepicker class="mx-2" v-model="date" locale="ru" hide-header placeholder="no date" size="sm"
                       :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                       @input="refresh">
    </b-form-datepicker>
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
          <template #first>
            <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Событие" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="event_type" v-bind:options="event_types" size="sm"> </b-form-select>
      </b-input-group-append>
    </b-input-group>
  </b-form>
  
  <div class="mt-2">
    <b-table :items="flightlog_filtered" :fields="flightlog_fields" 
             bordered head-variant="dark" small striped responsive hover>
      <template #cell(system)="row">
        <b-link v-if="row.item.system_id" :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
        <div v-else>{{row.item.system}}</div>
      </template>      
      <template #cell(body)="row">
        <b-link v-if="row.item.station_id" :to="`/station/${row.item.station_id}`">{{row.item.body}}</b-link>
        <div v-else>{{row.item.body}}</div>
      </template>      
    </b-table>
  </div>
</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CmdrFlightLog',
    inject: ['getUserInfo'],
    components: {
    },
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            myonly: true,
            user_id: this.getUserInfo().id,
            users: [],
            event_type: null,
            event_types: [{ value:null, text:'Все' },
                          { value:'FSDJump', text:'Прыжок' },
                          { value:'SupercruiseExit', text:'Выход из гипера' },
                          { value:'Docked', text:'Стыковка' },
                         ],
            flightlog: [],
            flightlog_fields: [
                { key: 'username', label: 'Пилот', sortable: true, thStyle: {width:"150px"}, },
                { key: 'ship', label: 'Корабль', sortable: true, },
                { key: 'time_stamp', label: 'Время', sortable: true, thStyle: {width:"100px"},
                  formatter: value => {
                      return moment.unix(value).format("HH:mm");
                  }
                },
                { key: 'event', label: 'Событие', sortable: true, thStyle: {width:"200px"},
                  formatter: value => {
                      if (value == 'FSDJump') return 'Прыжок'
                      if (value == 'SupercruiseExit') return 'Выход из гипера'
                      if (value == 'Docked') return 'Стыковка'
                      return value;
                  },
                },
                { key: 'system', label: 'Система', sortable: true },
                { key: 'body', label: 'Тело', sortable: true },
                { key: 'body_type', label: 'Тип', thStyle: {width:"200px"}, },
                { key: 'jump_dist', label: 'Дист', thStyle: {width:"100px"}, class: "text-right" },
            ],
        }
    },
    computed: {
        flightlog_filtered() {
            return this.flightlog.filter(item => {
                return ((this.event_type === null || item.event == this.event_type));
            });
        },
    },
    async mounted() {
        this.$on('refresh', this.refresh);
        this.users = await WS.request('users');
    },
    methods: {
        async refresh() {
            try {
                if (this.date) {
                    this.flightlog = await WS.request('flightlog',
                                                      { date: this.date, user_id: this.user_id });
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
    }
}
</script>

<style>
.systemstable {
    /* overflow-y: scroll; */
    max-height: calc(100vh - 250px);
}

</style>
