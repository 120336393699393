<template>
<div class="p-2">
  <b-form-group>
    <b-form-checkbox v-model="show_instock" @input="refresh" switch > Только в наличии </b-form-checkbox>
  </b-form-group>
  
  <b-tabs card>
    <b-tab :title="type" v-for="types,type,idx in materials" :key="idx" class="mb-2">
      <b-card-group deck class="flex-wrap">
        <b-card no-body v-for="mats,cat,idx in types" :key="idx" :header="cat"
                header-bg-variant="dark" header-text-variant="white"
                class="mb-1" style="min-width: 400px;max-width: 600px;">
          <b-table-simple small responsive striped hover>
            <b-tbody>
              <b-tr v-for="mat,idx in mats" :key="idx">
                <b-td>{{ mat.name }}</b-td>
                <b-td>{{ mat.rarity }}</b-td>
                <b-td class="text-right">{{ mat.amount }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-card-group>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { WS } from '@/misc.js';

export default {
    name: 'CmdrMaterials',
    inject: ['getUserInfo'],
    components: {
    },
    data() {
        return {
            show_instock: true,
            user_id: null,
            material_type: 'Raw',
            materials: {},
            materials_fields: [
                { key: 'name', label: 'Название', sortable: true },
                { key: 'amount', label: 'Количество', sortable: true },
            ],
        }
    },
    watch: {
        async $route(to) {
            console.log('CmdrMaterials routed',to)
            if (to.name == 'materials') {
                if (to.params.user_id) {
                    this.user_id = to.params.user_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.user_id) {
            this.user_id = this.$route.params.user_id;
        }
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.materials = await WS.request('materials', { user_id: this.user_id,
                                                                 in_stock: this.show_instock } );
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
    }
}
</script>

<style>

</style>
