<template>
<div>
  <b-table :items="fleet" :fields="fleet_fields" bordered head-variant="dark" striped responsive hover
           @row-clicked="onClick">
    <template #cell(name)="row">
      {{row.item.name}}
      <span v-if="row.item.identifier"> ({{row.item.identifier}}) </span>
    </template>
    <template #cell(system)="row">
      <div v-if="row.item.system_id">
        <b-link :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
      </div>
      <div v-else>{{row.item.system}}</div>
    </template>
    <template #cell(station)="row">
      <div v-if="row.item.station_id" >
        <b-link :to="`/station/${row.item.station_id}`">{{row.item.station}}</b-link>
      </div>
      <div v-else >{{row.item.station}}</div>
    </template>
    <template #row-details="row">
      <b-card>
        <ship-loadout :user_id="row.item.user_id" :ship_id="row.item.ship_id"></ship-loadout>
      </b-card>
    </template>
  </b-table>
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import ShipLoadout from "@/components/ShipLoadout.vue";

export default {
    name: 'CmdrFleet',
    inject: ['getUserInfo'],
    components: {
        ShipLoadout,
    },
    data() {
        return {
            user_id: null,
            fleet: {},
            fleet_fields: [
                { key: 'type', label: 'Корабль', sortable: true, thStyle: {width:"300px"} },
                { key: 'name', label: 'Название', sortable: true,  },
                { key: 'system', label: 'Система', sortable: true, thStyle: {width:"300px"} },
                { key: 'station', label: 'Станция', sortable: true, thStyle: {width:"300px"} },
            ],
            loadout: {},
        }
    },
    watch: {
        async $route(to) {
            console.log('CmdrFleet routed',to)
            if (to.name == 'fleet') {
                if (to.params.user_id) {
                    this.user_id = to.params.user_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.user_id) {
            this.user_id = this.$route.params.user_id;
        }
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.fleet = await WS.request('fleet', this.user_id);
                this.fleet.forEach((item) => {
                    Vue.set(item,'_showDetails', false);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        onClick(item) {
            item._showDetails = !item._showDetails;
        },
    }
}
</script>

<style>

</style>
