<template>
<div>
  <b-form inline>
    <b-form-datepicker class="mx-2" v-model="date" locale="ru" hide-header placeholder="no date"
                       :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                       @input="refresh" v-show="!tickMode">
    </b-form-datepicker>
    <b-form-checkbox size="sm" class="mx-2" v-model="tickMode" @input="refresh" switch>
      по тику
    </b-form-checkbox>
    <b-input-group prepend="Тик" class="mx-2" size="sm" v-show="tickMode">
      <b-input-group-append>
        <b-form-select v-model="tick_id" v-bind:options="ticks" text-field="tick_text" value-field="id" @input="refresh" size="sm"> 
        </b-form-select>
      </b-input-group-append>
    </b-input-group>

    <!-- <b-form-checkbox class="mx-2" v-model="myonly" switch @input="refresh"> -->
    <!--   Только собственные -->
    <!-- </b-form-checkbox> -->
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
          <template #first>
            <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Тип" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="action_type" v-bind:options="action_types" size="sm"> </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Система" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="action_system" v-bind:options="action_summary.systems" size="sm">
          <template #first>
            <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-form-checkbox class="mx-2" v-model="groupedMode" switch size="sm" @input="refresh">
      режим группировки
    </b-form-checkbox>
  </b-form>

  <div v-if="groupedMode">
    <b-table :items="actions_grouped_filtered" :fields="actions_grouped_fields" 
             bordered head-variant="dark" striped responsive hover
             @row-clicked="onClick">
      <template #cell(system)="row">
        <b-link :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
      </template>      
      <template #row-details="row">
        <b-card>
          <b-card-text>
            <p v-for="line,idx in row.item.detail" :key="idx" class="m-1">
              {{line}}
            </p>
          </b-card-text>
        </b-card>
      </template>
    </b-table>
  </div>
  <div v-else class="mt-2">
    <b-table :items="actions_filtered" :fields="actions_fields" 
             bordered head-variant="dark" striped responsive hover
             @row-clicked="onClick">
      <template #cell(system)="row">
        <b-link :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
      </template>      
      <template #row-details="row">
        <b-card>
          <div v-if="row.item.target">
            <div v-if="row.item.target.name">
              Цель: {{row.item.target.name}}
            </div>
            <span v-if="row.item.target.ship">, корабль {{row.item.target.ship}} </span>
            <span v-if="row.item.target.faction">, фракция {{row.item.target.faction}}</span>
            <span v-if="row.item.target.rank">, ранг {{row.item.target.rank}}</span>
            <span v-if="row.item.target.legal_status == 'Clean'">, чистый</span>
            <span v-if="row.item.target.legal_status == 'Wanted'">, в розыске</span>
          </div>
        </b-card>
      </template>
    </b-table>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CmdrActions',
    inject: ['getUserInfo'],
    data() {
        return {
            tickMode: false,
            tick_id: null,
            date: moment().format('YYYY-MM-DD'),
            // myonly: true,
            user_id: this.getUserInfo().id,
            users: [],
            action_summary: {},
            action_system: null,
            action_type: null,
            action_types: [{value:null, text:'Все'},
                           {value:'Bounty', text:'Правосудие'},
                           {value:'CommitCrime', text:'Преступление'},
                           {value:'RedeemVoucher', text:'Обналичка'},
                          ],
            actions: [],
            actions_fields: [
                { key: 'username', label: 'Пилот', sortable: true, thStyle: {width:"150px"}, },
                { key: 'time_stamp', label: 'Время', sortable: true, thStyle: {width:"100px"},
                  formatter: value => {
                      return moment.unix(value).format("HH:mm");
                  }},
                { key: 'type', label: 'тип', sortable: true, thStyle: {width:"200px"},
                  formatter: value => {
                      if (value == 'Bounty') return 'Правосудие'
                      if (value == 'CommitCrime') return 'Преступление'
                      if (value == 'RedeemVoucher') return 'Обналичка'
                      return value;
                  },
                },
                { key: 'kind', label: 'вид', sortable: true, thStyle: {width:"200px"},
                  formatter: value => {
                      if (value == 'murder') return 'Убийство'
                      if (value == 'assault') return 'Нападение'
                      if (value == 'CombatBond') return 'Боевые'
                      if (value == 'bounty') return 'Пиратские'
                      if (value == 'ExplorationData') return 'Исследовательские'
                      if (value == 'PayFines') return 'Штраф'
                      if (value == 'dockingMinorTresspass') return 'НСД'
                      if (value == 'dockingMajorTresspass') return 'Диверсия'
                      return value;
                  },
                },
                { key: 'system', label: 'Система', sortable: true },
                { key: 'faction', label: 'Юрисдикция', sortable: true },
                { key: 'bounty', label: 'Награда', thStyle: {width:"100px"}, class: "text-right" },
                { key: 'fine', label: 'Штраф', thStyle: {width:"100px"}, class: "text-right" },
                { key: 'show_details', label: '' },
            ],
            groupedMode: false,
            actions_grouped: [],
            actions_grouped_fields: [
                { key: 'type', label: 'тип', sortable: true, 
                  formatter: value => {
                      if (value == 'Bounty') return 'Правосудие'
                      if (value == 'CommitCrime') return 'Преступление'
                      if (value == 'RedeemVoucher') return 'Обналичка'
                      return value;
                  },
                },
                { key: 'system', label: 'Система', sortable: true },
            ],
            
        }
    },
    computed: {
        actions_filtered() {
            return this.actions.filter(item => {
                return ((this.action_type === null || item.type == this.action_type) &&
                        (this.action_system === null || item.system_id == this.action_system));
            });
        },
        actions_grouped_filtered() {
            return this.actions_grouped.filter(item => {
                return ((this.action_type === null || item.type == this.action_type) &&
                        (this.action_system === null || item.system_id == this.action_system));
            });
        },
    },
    async mounted() {
        this.$on('refresh', this.refresh);
        this.users = await WS.request('users');
    },
    methods: {
        async refresh() {
            try {
                this.ticks = await WS.request('ticks');
                this.ticks.forEach((item) => {
                  if (this.tick_id === null && moment.unix(item.tick_from).isBefore()) {
                      this.tick_id = item.id;
                  } 
                  let ts1 = item.tick_from !== null ? moment.unix(item.tick_from).format("DD.MM.YYYY HH:mm") : 'неизвестно';
                  let ts2 = item.tick_to !== null ? moment.unix(item.tick_to).format("DD.MM.YYYY HH:mm") : 'неизвестно';
                  Vue.set(item,'tick_text', `${ts1} - ${ts2}`);
                });
                this.action_summary = await WS.request('action_summary', { date: this.date });
                if (this.groupedMode) {
                    if (this.tickMode) {
                        this.actions_grouped = await WS.request('actions_grouped_bytick', {
                            tick: this.tick_id, 
                            user_id: this.user_id,
                        });
                    } else {
                        this.actions_grouped = await WS.request('actions_grouped', {
                            date: this.date,
                            user_id: this.user_id,
                        });
                    }
                    this.actions_grouped.forEach((item) => {
                        Vue.set(item,'_showDetails', false);
                    });
                } else {
                    if (this.tickMode) {
                        this.actions = await WS.request('actions_bytick', {
                            tick: this.tick_id, 
                            user_id: this.user_id,
                        });
                    } else {
                        this.actions = await WS.request('actions', {
                            date: this.date,
                            user_id: this.user_id,
                        });
                    }
                    this.actions.forEach((item) => {
                        Vue.set(item,'_showDetails', false);
                    });
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        onClick(item) {
            item._showDetails = !item._showDetails;
        },
    }
}
</script>

<style>
.systemstable {
    /* overflow-y: scroll; */
    max-height: calc(100vh - 250px);
}

</style>
