<template>
<div class="d-flex flex-column m-1">
  <b-card>
      <b-row>
        <b-col>
          <b-row>
            <b-col>Система</b-col>
            <b-col>
              <system-selector ref="systemSelector"
                               v-model="system_id" @input="refresh"></system-selector>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>Обновлено</b-col>
            <b-col> {{ system_data.updated | timestamp }} </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="system_data.population > 0">
        <b-col>
          <b-row>
            <b-col>Экономика</b-col>
            <b-col>{{ system_data.economy }}</b-col>
          </b-row>
          <b-row>
            <b-col>Население</b-col>
            <b-col>{{ system_data.population | num2text }}</b-col>
          </b-row>
          <b-row>
            <b-col>Безопасность</b-col>
            <b-col>{{ system_data.security }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>Контролирующая фракция</b-col>
            <b-col>{{ system_data.control_faction }}</b-col>
          </b-row>
          <b-row>
            <b-col>Правительство</b-col>
            <b-col>{{ system_data.government }}</b-col>
          </b-row>
          <b-row>
            <b-col>Принадлежность</b-col>
            <b-col>{{ system_data.allegiance }}</b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>Политика</b-col>
            <b-col>{{ system_data.powers }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="system_data.favor" switch @change="setFavor">
            Добавить в избранное
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="auto">
                Расстояние до 
            </b-col>
            <b-col>
              <system-selector ref="systemSelector2" v-model="calc_dist_to" @input="calc_dist"></system-selector> 
            </b-col>
            <b-col>
              {{ distance }}
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>Особенности:</b-col>
            <b-col>{{ system_data.scenarios }}</b-col>
          </b-row>
        </b-col>
      </b-row>
  </b-card>

  <div class="flex-grow-1">
  <b-card no-body>
    <b-tabs card v-model="activeTab">
      
      <b-tab title="Фракции" >
        <b-form-group>
          <b-form-checkbox v-model="show_retreated" switch> показать отступившие </b-form-checkbox>
        </b-form-group>
        <b-table :items="filteredFactions" :fields="factions_fields" 
                 bordered head-variant="dark" small striped responsive hover no-border-collapse
                 :tbody-tr-class="factionRowClass">
          <template #cell(name)="row">
            <b-link :to="`/faction/${row.item.id}`">{{row.item.name}}</b-link>
          </template>
          <template #cell(diff)="row">
            {{ row.item.diff|num2text }} ({{ row.item.diff2|num2text }})
          </template>
        </b-table>
      </b-tab>
      
      <b-tab title="История" >
        <b-card no-body >
          <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
        </b-card>
      </b-tab>
      
      <b-tab title="Станции" >
        <b-table :items="stations" :fields="stations_fields" 
                 bordered head-variant="dark" small striped responsive hover
                 @row-clicked="stationClick">
        </b-table>
      </b-tab>
      
      <b-tab title="Конфликты" >
        <b-list-group horizontal v-for="(conflict,idx) in conflicts" :key="idx">
          <b-list-group-item>
            {{ conflict.type | conflict_type }} в {{ conflict.status | conflict_status }}
            длится не менее {{conflict.totaldays}} дней
          </b-list-group-item>
          <b-list-group-item variant="success">
            Фракция <b-link :to="`/faction/${conflict.green.id}`">{{conflict.green.name}}</b-link>
            одержала {{conflict.green.wondays}} побед
            <template v-if="conflict.green.stake">
              ставка {{conflict.green.stake}}
            </template>
          </b-list-group-item>
          <b-list-group-item variant="danger">
            Фракция <b-link :to="`/faction/${conflict.red.id}`">{{conflict.red.name}}</b-link>
            одержала {{conflict.red.wondays}} побед
            <template v-if="conflict.red.stake">
              ставка {{conflict.red.stake}}
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-tab>
      
      <b-tab title="Цели экспансии" >
        <b-table :items="expans" :fields="expans_fields"
                 bordered head-variant="dark" small responsive hover no-border-collapse
                 :tbody-tr-class="expansRowClass">
          <template #cell(name)="data">
            <b-link :to="`/system/${data.item.id}`">{{data.item.name}}</b-link>
            <span>
              <b-button size="sm" variant="link" class="ml-2 p-0" 
                        @click="copyClipboard(data.item.name)">
                <b-icon icon="stickies"></b-icon>
              </b-button>
            </span>
          </template>
          <template #cell(government)="data">
            {{ data.item.government }}
            <small class="float-right text-muted">
              <b-link :to="`/faction/${data.item.faction_id}`">{{data.item.faction}}</b-link>
            </small>
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Достопримечательности" >
        <b-table :items="attractions" :fields="attractions_fields" 
                 bordered head-variant="dark" small striped responsive hover
                 @row-clicked="attractionClick">
          <template #row-details="row">
            <b-card>
              <div v-if="row.item.group_name == 'megaship'">
                <p>Турелей: {{row.item.turrets}}</p>
                <p>Груз:</p>
                <b-table :items="row.item.cargo" :fields="cargo_fields"
                         hover striped small responsive head-variant="dark">
                </b-table>
              </div>
              <div v-if="row.item.group_name == 'Settlement'" >
                <vue-picture-swipe v-if="row.item.pictures" :items="row.item.pictures"></vue-picture-swipe>
                <!-- <b-carousel :interval="20000" controls indicators img-height="480"> -->
                <!--   <b-carousel-slide v-for="(img,idx) in row.item.images" :key="idx" -->
                <!--                     :img-src="`/icons/${img}`" > -->
                <!--   </b-carousel-slide> -->
                <!-- </b-carousel> -->
              </div>
            </b-card>
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Тела">
        <b-table :items="bodies" :fields="bodies_fields" 
                 bordered head-variant="dark" small striped responsive hover
                 @row-clicked="bodyClick">
          <template #row-details="row">
            <b-card>
              <div v-if="row.item.type == 'Planet'">
                <b-row>
                  <b-col>Радиус:</b-col>
                  <b-col>{{ row.item.info.radius }}</b-col>
                  <b-col>Гравитация:</b-col>
                  <b-col>{{ row.item.info.gravity }}</b-col>
                  <b-col>Земных масс:</b-col>
                  <b-col>{{ row.item.info.earthMasses }}</b-col>
                </b-row>
                <b-row>
                  <b-col>Тип вулканизма:</b-col>
                  <b-col>{{ row.item.info.volcanismType }}</b-col>
                  <b-col>Состав грунта:</b-col>
                  <b-col>{{ row.item.info.solidComposition }}</b-col>
                  <b-col>Резерв:</b-col>
                  <b-col>{{ row.item.info.reserveLevel }}</b-col>
                </b-row>
                <b-row>
                  <b-col>Тип атмосферы:</b-col>
                  <b-col>{{ row.item.info.atmosphereType }}</b-col>
                  <b-col>Состав атмосферы:</b-col>
                  <b-col>{{ row.item.info.atmosphereComposition }}</b-col>
                  <b-col>Терраформирование:</b-col>
                  <b-col>{{ row.item.info.terraformingState }}</b-col>
                </b-row>
                <b-row>
                  <b-col>Орбитальный период:</b-col>
                  <b-col>{{ row.item.info.orbitalPeriod }}</b-col>
                  <b-col>Орбитальное смещение:</b-col>
                  <b-col>{{ row.item.info.orbitalInclination }}</b-col>
                  <b-col>Эксцентриситет орбиты:</b-col>
                  <b-col>{{ row.item.info.orbitalEccentricity }}</b-col>
                </b-row>
                <b-row>
                  <b-col>Аргумент перицентра:</b-col>
                  <b-col>{{ row.item.info.argOfPeriapsis }}</b-col>
                  <b-col>Период вращения:</b-col>
                  <b-col>{{ row.item.info.rotationalPeriod }}</b-col>
                  <b-col>Наклон оси вращения:</b-col>
                  <b-col>{{ row.item.info.axialTilt }}</b-col>
                </b-row>
                <b-row>
                  <b-col>Большая полуось:</b-col>
                  <b-col>{{ row.item.info.semiMajorAxis }}</b-col>
                  <b-col>Давление на поверхности:</b-col>
                  <b-col>{{ row.item.info.surfacePressure }}</b-col>
                  <b-col>Температура на поверхности:</b-col>
                  <b-col>{{ row.item.info.surfaceTemperature }}</b-col>
                </b-row>
              </div>
              <b-table-simple striped small responsive v-if="row.item.rings">
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>Кольцо</b-th>
                    <b-th>Тип</b-th>
                    <b-th>Масса</b-th>
                    <b-th>Внутр.радиус</b-th>
                    <b-th>Внушн.радиус</b-th>
                    <b-th>Плотность</b-th>
                    <b-th>Сигналы</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="ring,idx in row.item.rings" :key="idx">
                    <b-td>{{ ring.name }}</b-td>
                    <b-td>{{ ring.type }}</b-td>
                    <b-td class="text-right">{{ ring.mass }}</b-td>
                    <b-td class="text-right">{{ ring.inner }}</b-td>
                    <b-td class="text-right">{{ ring.outer }}</b-td>
                    <b-td class="text-right">{{ ring.density }}</b-td>
                    <b-td>{{ ring.signals }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Окресности" >
        <b-table :items="bubble" :fields="bubble_fields"
                 bordered head-variant="dark" small striped responsive hover no-border-collapse
                 @row-clicked="bubbleClick">
          <template #cell(name)="data">
            <b-link :to="`/system/${data.item.id}`">{{data.item.name}}</b-link>
          </template>
        </b-table>
      </b-tab>
      
    </b-tabs>
  </b-card>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import { WS } from '@/misc.js';
import moment from 'moment';
import SystemSelector from '@/components/SystemSelector.vue';
// import Station from '@/components/Station.vue';

import VuePictureSwipe from 'vue-picture-swipe';
// Vue.component('vue-picture-swipe', VuePictureSwipe);


Vue.use(VueApexCharts);
moment.locale('ru');

export default {
    name: 'SystemView',
    inject: ['getUserInfo'],
    components: {
        SystemSelector,
        apexchart: VueApexCharts,
        VuePictureSwipe
        // Station,
    },
    data() {
        return {
            activeTab: 0,
            system_id: null,
            system_data: {},
            show_retreated: false,
            factions: [],
            factions_fields: [
                { key: 'name', label: 'Название', sortable: true, thStyle: {width:"400px"},
                  stickyColumn: true, isRowHeader: true },
                { key: 'influence', label: 'Влияние', sortable: true, variant: 'info', class: "text-right",
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }
                },
                { key: 'diff', label: 'Изм', sortable: true, thStyle: {width:"120px"},
                  tdClass: (value) => {
                      if (value === null) return
                      if (value >= 3.0) { return 'table-primary' }
                      else if (value >= 1.0) { return 'table-success' }
                      else if (value <= -3.0) { return 'table-danger' }
                      else if (value <= -1.0) { return 'table-warning' }
                  },
                },
                { key: 'government', label: 'Правительство', sortable: true },
                { key: 'allegiance', label: 'Принадлежность', sortable: true },
                { key: 'active_states', label: 'Акт.Состояния' },
                { key: 'pending_states', label: 'Ож.Состояния' },
                { key: 'recovering_states', label: 'Пр.Состояния' },
                { key: 'reputation', label: 'Реп.', sortable: true, class: "text-right",
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  },
                  tdClass: (value) => {
                      if (!value) return
                      if (value >= 75) { return 'table-success' }
                      else if (-50 <= value < 0) { return 'table-warning' }
                      else if (value < -50) { return 'table-danger' }
                  },
                },
            ],
            stations: [],
            stations_fields: [
                { key: 'name', label: 'Название', thStyle: {width:"500px"} },
                { key: 'type', label: 'Тип' },
                { key: 'faction', label: 'Фракция' },
                { key: 'economies', label: 'Экономика' },
                { key: 'max_landing_pad_size', label: 'Пл', thStyle: {width:"25px"} },
                { key: 'distance', label: 'Дистанция', class: "text-right", thStyle: {width:"50px"} },
            ],
            expans: [],
            expans_fields: [
                { key: 'name', label: 'Название', sortable: true, thStyle: {"min-width":"250px"} },
                { key: 'population', label: 'Население', sortable: true, class: "text-right",
                  formatter: value => {
                      // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
                      if (value >= 1000000000) {
                          return (value / 1000000000).toFixed(2) + ' млрд.'
                      } else if (value >= 1000000) {
                          return (value / 1000000).toFixed(2) + ' млн.'
                      } else if (value >= 1000) {
                          return (value / 1000).toFixed(2) + ' тыс.'
                      } else {
                          return (value === null) ? '--' : value.toFixed(2);
                      }
                  }},
                { key: 'economy', label: 'Экономика', sortable: true, thStyle: {"min-width":"200px"} },
                { key: 'power', label: 'Сила', sortable: true, thStyle: {"min-width":"200px"} },
                { key: 'allegiance', label: 'Принадлежность', sortable: true },
                { key: 'government', label: 'Правительство', sortable: true, thStyle: {"min-width":"300px"} },
                { key: 'control_influence', label: 'Вл', sortable: true, class: "text-right",
                  thStyle: {width:"70px"},
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }
                },
                { key: 'outer_influence', label: 'Вл', sortable: true, class: "text-right",
                  thStyle: {width:"70px"},
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }
                },
                { key: 'factions', label: 'Фр', sortable: true, class: "text-right", thStyle: {width:"70px"} },
                { key: 'stations', label: 'Ст', sortable: true, class: "text-right", thStyle: {width:"70px"} },
                { key: 'distance', label: 'Раст', sortable: true, class: "text-right",
                  thStyle: {width:"100px"},
                  formatter: value => {
                      return value.toFixed(2)
                  }
                },
                { key: 'updated', label: 'Обновлено', sortable: true,
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
            conflicts: [],
            chartSeries: [],
            chartOptions: {
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: [
                    {
                        title: {
                            text: "Влияние"
                        }
                    },
                ],
                tooltip: {
                    x: {
                        format: 'dd.MM.yy HH:mm'
                    },
                },
            },
            attractions: [],
            attractions_fields: [
                { key: 'name', label: 'Название', thStyle: {width:"500px"} },
                { key: 'group_name', label: 'Группа', thStyle: {width:"200px"} },
                { key: 'body', label: 'Планета' },
                { key: 'distance', label: 'Расст.', class: "text-right", thStyle: {width:"50px"} },
                { key: 'settlement_size', label: 'Размер', thStyle: {width:"200px"} },
                { key: 'settlement_security', label: 'Безопасность', thStyle: {width:"200px"} },
                { key: 'type', label: 'Тип', thStyle: {width:"200px"} },
            ],
            cargo_fields: [
                { key: 'category', label: 'Категория', sortable: true,  },
                { key: 'name', label: 'Товар', sortable: true, },
            ],
            bodies: [],
            bodies_fields: [
                { key: 'name', label: 'Название', thStyle: {width:"300px"} },
                { key: 'type', label: 'Тип', thStyle: {width:"200px"} },
                { key: 'subtype', label: 'Подтип', thStyle: {width:"200px"} },
                { key: 'distance', label: 'Дист', thStyle: {width:"100px"} },
                { key: 'is_landable', label: 'Посадка', thStyle: {width:"100px"} },
                { key: 'materials', label: 'Материалы'},
                { key: 'rings_summary', label: 'Кольца'},
                { key: 'signals', label: 'Сигналы'},
            ],
            bubble: [],
            bubble_fields: [
                { key: 'name', label: 'Название', sortable: true, thStyle: {width:"400px"} },
                { key: 'security', label: 'Безопасность', sortable: true, thStyle: {width:"100px"} },
                { key: 'population', label: 'Население', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}, 
                  formatter: value => {
                      if (value >= 1000000000) {
                          return (value / 1000000000).toFixed(2) + ' млрд.'
                      } else if (value >= 1000000) {
                          return (value / 1000000).toFixed(2) + ' млн.'
                      } else if (value >= 1000) {
                          return (value / 1000).toFixed(2) + ' тыс.'
                      } else {
                          return (value === null) ? '--' : value.toFixed(2);
                      }
                  }},
                { key: 'power', label: 'Сила', sortable: true, thStyle: {width:"200px"}, },
                { key: 'allegiance', label: 'Принадлежность', sortable: true },
                { key: 'government', label: 'Правительство', sortable: true },
                { key: 'distance', label: 'Раст', sortable: true, class: "text-right",
                  thStyle: {width:"100px"},
                  formatter: value => {
                      return value.toFixed(2)
                  }
                },
                { key: 'star_type', label: 'Тип звезды', thStyle: {width:"300px"}, },
                { key: 'is_scoopable', label: 'Заправка', thStyle: {width:"75px"},
                  formatter: value => {
                      return value ? 'Да' : 'Нет';
                  }
                },
                { key: 'body_amount', label: 'Тел', thStyle: {width:"75px"}, },
                { key: 'landable_amount', label: 'Посадка', thStyle: {width:"75px"}, },
            ],
            distance: null,
            calc_dist_to: null,
        }
    },
    computed: {
        filteredFactions() {
            return this.factions.filter(item => {
                return (this.show_retreated || (item.influence > 0.00));
            });
        },
    },
    watch: {
        async $route(to) {
            console.log("SV routed",to);
            if (to.name == 'system') {
                if (to.params.system_id) {
                    this.system_id = to.params.system_id;
                } else {
                    this.system_id = this.getUserInfo().system_id;
                }
                this.activeTab = 0;
                await this.refresh();
            }
        }
    },
    async mounted() {
        console.log("SV mounted");
        if (this.$route.params.system_id) {
            this.system_id = this.$route.params.system_id;
        } else {
            this.system_id = this.getUserInfo().system_id;
        }
        this.activeTab = 0;
        await this.refresh();
    },
    async deactivated() {
        console.log("SV deactivated");
        this.system_data = {};
        this.factions = [];
        this.stations = [];
        this.attractions = []
        this.expans = [];
        this.conflicts = [];
        this.bubble = [];
        this.bodies = [];
    },
    methods: {
        async refresh() {
            console.log("SV refresh");
            try {
                this.system_data = await WS.request('system_data', this.system_id);
                this.factions = await WS.request('system_factions', this.system_id);
                this.stations = await WS.request('system_stations', this.system_id);
                this.attractions = await WS.request('attractions', this.system_id);
                this.attractions.forEach((item) => {
                    Vue.set(item,'_showDetails', false);
                    let pictures = []
                    if (item.images) {
                        item.images.forEach((img) => {
                            pictures.push({ src: "/icons/"+img.filename,
                                            thumbnail: "/thumbnails/"+img.filename,
                                            w: img.w, h: img.h})
                        });
                        Vue.set(item,'pictures', pictures);
                    }
                });
                this.expans = await WS.request('system_expans', this.system_id);
                this.conflicts = await WS.request('system_conflicts', this.system_id);
                this.chartSeries = [];
                this.factions.forEach((faction) => {
                    if (faction.history && faction.history.length > 0) {
                        let dataseries = faction.history.map(item => ([item.updated_at*1000, item.influence]));
                        this.chartSeries.push({name: faction.name, data: dataseries});
                    }
                })
                this.bodies = await WS.request('bodies', this.system_id);
                this.bodies.forEach((item) => {
                    Vue.set(item,'_showDetails', false);
                });
                this.bubble = await WS.request('system_bubble', { system_id: this.system_id, range: 20 });
                await this.calc_dist()
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            } 
        },
        factionRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.control) return 'table-primary'
            if (item.influence === null || item.influence == 0.00) return 'table-secondary'
        },
        expansRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.presense == 'P' || item.presense == 'R') return 'table-secondary'
            if (item.factions < 7) return 'table-success'
            if (item.has_retreat) return 'table-warning'
        },
        // onRowSelected(item) {
        //     this.$router.push({ name: 'faction', params: { faction_id: item.id } });
        // },
        goExpansSystem(item) {
            this.$router.push({ name: 'system', params: { system_id: item.id } });
        },
        stationClick(item) {
            this.$router.push({ name: 'station', params: { station_id: item.id } });
            // item._showDetails = !item._showDetails;
        },
        attractionClick(item) {
            item._showDetails = !item._showDetails;
        },
        bodyClick(item) {
            item._showDetails = !item._showDetails;
        },
        bubbleClick(item) {
            item._showDetails = !item._showDetails;
        },
        async setFavor() {
            try {
                this.system_data.favor = await WS.request('favor_system', this.system_id);
                await this.$refs.systemSelector.reset();
            } catch (err) {
                this.$bvModal.msgBoxOk(`setFavor: ${err}`);
            } 
        },
        async calc_dist() {
          try {
                if (this.calc_dist_to) {
                  this.distance = await WS.request('calc_distance', { from: this.system_id, to: this.calc_dist_to } );
                  await this.$refs.systemSelector2.reset();
                } else {
                  this.distance = null
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`setFavor: ${err}`);
            } 
        },
        async copyClipboard(value) {
          navigator.clipboard.writeText(value);
        },
    },
    filters: {
        timestamp(ts) {
            // return moment.unix(ts).utcOffset(this.userInfo.utcoffset).calendar();
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        datetime(ts) {
            let tm = moment.unix(ts);
            if (tm.isAfter(moment().startOf('date'))) {
                return tm.format("HH:mm");
            } else {
                return tm.format("DD.MM.YYYY");
            }
        },
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value === null) return '--';
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return value.toFixed(2);
            }
        },
        conflict_type(value) {
            if (value == "war") {
                return "Война";
            } else if (value == "civilwar") {
                return "Гражданская война";
            } else if (value == "election") {
                return "Выборы";
            } else {
                return value;
            }
        },
        conflict_status(value) {
            if (value == 'active') {
                return 'активном состоянии';
            } else if (value == 'pending') {
                return 'состоянии ожидания';
            } else {
                return 'состоянии завершения';
            }
        },
    }
}
</script>

<style>
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
