<template>
<div class="d-flex flex-column m-1">
  <b-card id="infocard">
    <b-row>
      <b-col cols="2">Фракция</b-col>
      <b-col> 
        <faction-selector ref="factionSelector" v-model="faction_id" @input="refresh"></faction-selector> 
      </b-col>
      <b-col cols="2">Домашняя система</b-col>
      <b-col> {{faction_data.home_system}} </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">Правительство</b-col>
      <b-col> {{faction_data.government}} </b-col>
      <b-col cols="2">Всего систем</b-col>
      <b-col> {{faction_data.total_systems}} </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">Принадлежность</b-col>
      <b-col> {{faction_data.allegiance}} </b-col>
      <b-col cols="2">Под контролем</b-col>
      <b-col> {{faction_data.total_control}} </b-col>
    </b-row>
    <b-row>
      <b-col></b-col>
      <b-col>
        <b-form-checkbox v-model="faction_data.favor" switch @change="setFavor">
          Добавить в избранное
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-card>
  
  <b-form-group>
    <b-form-checkbox v-model="show_retreated" switch> показать покинутые </b-form-checkbox>
  </b-form-group>
  
  <b-table :items="systems_filtered" :fields="systems_fields" 
           bordered head-variant="dark" small striped responsive hover no-border-collapse sticky-header="10000px"
           :tbody-tr-class="systemsRowClass">
    <template #head(name)="scope">
        <div class="text-nowrap">{{scope.label}}</div>
    </template>
    <template #head()="scope">
        <div class="text-nowrap"> {{ scope.label }} </div>
    </template>
    <template #cell(name)="row">
        <b-link :to="`/system/${row.item.id}`">{{row.item.name}}</b-link>
        <span>
            <b-button size="sm" variant="link" class="ml-2 p-0" @click="copyClipboard(row.item.name)">
                <b-icon icon="stickies"></b-icon>
            </b-button>
        </span>
    </template>
    <template #cell(diff)="row">
      {{ row.item.diff|num2text }} ({{ row.item.diff2|num2text }})
    </template>
  </b-table>
</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';
import FactionSelector from '@/components/FactionSelector.vue';

moment.locale('ru');

export default {
    name: 'FactionView',
    inject: ['getUserInfo'],
    components: {
        FactionSelector,
    },
    data() {
        return {
            faction_id: null,
            faction_data: {},
            systems: [],
            systems_fields: [ 
            { 
                key: 'name', label: 'Название', sortable: true, 
                thStyle: {"min-width":"300px"}, stickyColumn: true, isRowHeader: true
            },
            { 
                key: 'influence', label: 'Влиян', sortable: true, variant: 'info',
                class: "text-right", thStyle: {width:"70px"},
                formatter: value => {
                    return value.toFixed(2)
                }
            },
            { 
                key: 'diff', label: 'Изм', sortable: true, thStyle: {"min-width":"100px"},
                tdClass: (value) => {
                    if (value === null) return
                    if (value >= 3.0) { return 'table-primary' }
                    else if (value >= 1.0) { return 'table-success' }
                    else if (value <= -3.0) { return 'table-danger' }
                    else if (value <= -1.0) { return 'table-warning' }
                },
            },
            { key: 'economy', label: 'Экономика', sortable: true, thStyle: {"min-width":"400px"} },
            { 
                key: 'population', label: 'Население', sortable: true, class: "text-right",
                formatter: value => {
                    if (value >= 1000000000) {
                        return (value / 1000000000).toFixed(2) + ' млрд.'
                    } else if (value >= 1000000) {
                        return (value / 1000000).toFixed(2) + ' млн.'
                    } else if (value >= 1000) {
                        return (value / 1000).toFixed(2) + ' тыс.'
                    } else {
                        return value.toFixed(2)
                    }
                }
            },
            { key: 'factions', label: 'Фр', class: "text-right", thStyle: {width:"50px"} },
            { key: 'stations', label: 'Ст', class: "text-right", thStyle: {width:"50px"} },
            { key: 'power', label: 'Сила', sortable: true, thStyle: {"min-width":"250px"} },
            { key: 'states', label: 'Состояния' },
            { key: 'faction', label: 'Фракция', sortable: true, thStyle: {"min-width":"400px"} },
            { 
                key: 'updated', label: 'Обновлено', sortable: true,
                formatter: value => {
                    return moment.unix(value).fromNow(true);
                }
            },
            { key: 'show_details', label: '' },
            ],
            offset: null,
            tableHeight: null,
            show_retreated: false,
        }
    },
    computed: {
        systems_filtered() {
            return this.systems.filter(item => {
                return (this.show_retreated || (item.influence > 0.00));
            });
        },
    },
    watch: {
        async $route(to) {
            console.log('FV routed',to)
            if (to.name == 'faction') {
                if (to.params.faction_id) {
                    this.faction_id = to.params.faction_id;
                } else {
                    this.faction_id = this.getUserInfo().faction_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        console.log('FV mounted')
        if (this.$route.params.faction_id) {
            this.faction_id = this.$route.params.faction_id;
        } else {
            this.faction_id = this.getUserInfo().faction_id;
        }
        await this.refresh();
    },
    async deactivated() {
        console.log("FV deactivated");
        this.faction_data = {};
        this.systems = [];
    },
    methods: {
        async refresh() {
            console.log("FV refresh");
            try {
                this.faction_data = await WS.request('faction_data', this.faction_id);
                this.systems = await WS.request('faction_systems', this.faction_id);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        systemsRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.influence <= 7.5) return 'table-danger'
            if (item.influence >= 70.0) return 'table-info'
            if (item.faction_id != this.faction_id) return 'table-warning'
        },
        // onRowSelected(item) {
        //     this.$router.push({ name: 'system', params: { system_id: item.id } });
        // },
        async setFavor() {
            try {
                this.faction_data.favor = await WS.request('favor_faction', this.faction_id);
                await this.$refs.factionSelector.reset();
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        copyClipboard(value) {
            navigator.clipboard.writeText(value);
        },
    },
    filters: {
        num2text(value) {
            if (value === null) return '--';
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return value.toFixed(2);
            }
        },
    }
}
</script>

<style>
/*
Fix an issue in vue-bootstrap v2.22.0:
https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky !important;
}
</style>
