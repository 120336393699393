<template>
<div class="p-2">
  <b-card>
    <b-row>
      <b-col>
        <b-form-group label="Текущая система">
          <div class="d-flex">
            <system-selector v-model="system_id" class="flex-fill"></system-selector>
            <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
                       class="d-inline-block">
              <b-button :disabled="busy" class="mx-2" variant="primary" @click="refresh_profile">
                Где я?
              </b-button>
            </b-overlay>
          </div>
        </b-form-group>
        
        <b-form-group label="Радиус" label-cols label-align="right">
          <b-input type="number" v-model="range"></b-input>
        </b-form-group>
        <b-form-group label="Расстояние" label-cols label-align="right">
          <b-input type="number" v-model="distance_to_star"></b-input>
        </b-form-group>
      </b-col>
      <b-col cols=8>
        <b-row>
          <b-col>
            <b-form-group label="Тип станции">
              <b-form-select v-model="station_type">
                <b-form-select-option :value="null">Не важно</b-form-select-option>
                <b-form-select-option value="bigspace">Большая космическая станция</b-form-select-option>
                <b-form-select-option value="big">Любая с большой площадкой</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Экономика Станции">
              <b-form-select v-model="station_economy">
                <b-form-select-option :value="null">Не важно</b-form-select-option>
                <b-form-select-option value="Agriculture">Сельское хозяйство</b-form-select-option>
                <b-form-select-option value="HighTech">Высокие технологии</b-form-select-option>
                <b-form-select-option value="Industrial">Промышленная</b-form-select-option>
                <b-form-select-option value="Extraction">Добыча</b-form-select-option>
                <b-form-select-option value="Refinery">Переработка</b-form-select-option>
                <b-form-select-option value="Tourism">Туризм</b-form-select-option>
                <b-form-select-option value="Military">Военная</b-form-select-option>
                <b-form-select-option value="Service">Услуги</b-form-select-option>
                <b-form-select-option value="Colony">Колония</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Служба">
              <b-form-select v-model="station_service" >
                <b-form-select-option :value="null">Не важно</b-form-select-option>
                <b-form-select-option value="facilitator">Юрист</b-form-select-option>
                <b-form-select-option value="materialtrader_raw">Меняла камней</b-form-select-option>
                <b-form-select-option value="materialtrader_manufactured">Меняла деталек</b-form-select-option>
                <b-form-select-option value="materialtrader_encoded">Меняла данных</b-form-select-option>
                <b-form-select-option value="techbroker_guardian">Техноброкер Стражей</b-form-select-option>
                <b-form-select-option value="techbroker_human">Техноброкер Человеков</b-form-select-option>
                <b-form-select-option value="blackmarket">Черный рынок</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Статус Силы">
              <b-form-select v-model="station_power_state" :options="power_states">
                <template #first>
                  <b-form-select-option :value="null">Не важно</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Сила">
              <b-form-select v-model="station_power" :options="powers"> 
                <template #first>
                  <b-form-select-option :value="null">Не важно</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button-group>
          <b-button variant="outline-success" @click="doSearch">Найти</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
  
  <b-table :items="result" :fields="result_fields" 
           bordered head-variant="dark" small striped responsive hover 
           :tbody-tr-class="expansRowClass">
    <template #cell(system)="data">
      <b-link :to="`/system/${data.item.system_id}`">{{data.item.system}}</b-link>
      <span>
        <b-button size="sm" variant="link" class="ml-2 p-0" 
                  @click="copyClipboard(data.item.system)">
          <b-icon icon="stickies"></b-icon>
        </b-button>
      </span>
    </template>
    <template #cell(station)="data">
      <b-link :to="`/station/${data.item.station_id}`">{{data.item.station}}</b-link>
      <div class="float-right text-muted"> {{data.item.faction}} </div>
    </template>
    <template #cell(landing_pad)="data">
      {{data.item.landing_pad}}
      <div v-if="data.item.is_planetary">
        <small class="float-right text-muted">Планетарка</small>
      </div>
    </template>
  </b-table>
  
</div>
</template>

<script>
import { WS } from '@/misc.js';
import SystemSelector from '@/components/SystemSelector.vue';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'StationSearch',
    inject: ['getUserInfo'],
    components: {
        SystemSelector,
    },
    data() {
        return {
            busy: false,
            system_id: null,
            range: 50,
            distance_to_star: 5000,
            station_type: null,
            station_economy: null,
            station_service: null,
            station_power_state: null,
            station_power: null,
            result: [],
            result_fields: [
                { key: 'system', label: 'Система', sortable: true },
                { key: 'distance', label: 'Расст', sortable: true,
                  class: "text-right", thStyle: {width:"50px"} },
                { key: 'station', label: 'Станция' },
                { key: 'distance_to_star', label: 'Дистанция', sortable: true,
                  class: "text-right", thStyle: {width:"50px"} },
                { key: 'landing_pad', label: 'Площадка' },
                { key: 'updated', label: 'Обновлено', sortable: true, thStyle: {width:"100px"},
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
            powers: [
              { 
                label: "Империя",
                options: ["Denton Patreus", "Zemina Torval","A. Lavigny-Duval", "Aisling Duval"]
              },
              {
                label: "Федералы",
                options: ["Felicia Winters", "Zachary Hudson"]
              },
              {
                label: "Альянс",
                options: ["Edmund Mahon"]
              },
              {
                label: "Независимые",
                options: ["Li Yong-Rui", "Yuri Grom", "Archon Delaine","Pranav Antal"]
              },
            ],
            power_states: [ 
              { value: "Controlled", text: "Контролируется" },
              { value: "HomeSystem", text: "Столица" },
              { value: "Prepared", text: "Подготовлена" },
              { value: "Turmoil", text: "Кризис" },
              { value: "Contested", text: "Оспаривается" },
              { value: "Exploited", text: "Эксплуатируется" },
              { value: "InPrepareRadius", text: "В радиусе подготовки" },
            ],
        }
    },
    async mounted() {
        try {
            let profile = await WS.request('user_profile');
            this.system_id = profile.system_id;
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_profile: ${err}`);
        } 
    },
    methods: {
        async refresh_profile() {
            this.busy = true;
            try {
                let profile = await WS.request('profile');
                this.system_id = profile.system_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(`profile: ${err}`);
            }
            this.busy = false;
        },
        async doSearch() {
            try {
                this.result = await WS.request('search_station',
                                               { system_id: this.system_id,
                                                 range: this.range,
                                                 distance_to_star: this.distance_to_star,
                                                 station_type: this.station_type,
                                                 station_economy: this.station_economy,
                                                 station_service: this.station_service,
                                                 station_power: this.station_power,
                                                 station_power_state: this.station_power_state
                                               });
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        copyClipboard(value) {
          navigator.clipboard.writeText(value);
        },
    },
    filters: {
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        bool2text(value) {
            return value ? 'Да' : 'Нет';
        }
    },
}
</script>

<style>
/*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.maincontainer {
    height: 100vh;
}
.ddcontainer {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
}
</style>
