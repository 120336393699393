<template>
<div>
  <b-dropdown :text="valueText" :disabled="disabled" block >
    <b-form-input v-model="search" size="sm" placeholder="поиск..."
                  debounce="500" v-on:update="refresh"></b-form-input>
    <b-form-checkbox v-model="populatedOnly" size="sm">Только населенные</b-form-checkbox>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="mydropdownitems">
      <b-dropdown-group header="Избранное" v-if="favor.length > 0">
        <b-dropdown-item v-for="fv in favor" :key="fv.id" @click="$emit('input',fv.id)">
          <em>{{fv.name}}</em>
        </b-dropdown-item>
      </b-dropdown-group>
      <b-dropdown-divider v-if="favor.length > 0"></b-dropdown-divider>
      <b-dropdown-item v-for="opt in options" :key="opt.id" @click="$emit('input', opt.id)">
        {{opt.name}}  ({{opt.economy}})
      </b-dropdown-item>
    </div>
  </b-dropdown>
</div>
</template>

<script>
import { WS } from  '@/misc.js';

export default {
    name: "SystemSelector",
    props: {
        value: Number,
        disabled: { type: Boolean, default: false },
        nearest: { type: Boolean, default: false },
    },
    data: () => ({
        populatedOnly: true,
        options: [],
        search: '',
        favor: [],
    }),
    computed: {
        filteredOptions() {
            return this.options.filter(item => {
                return (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
            });
        },
        valueText() {
            let opt = this.options.find(item => item.id == this.value);
            return opt ? opt.name : 'Неизвестный';
        }
    },
    watch: {
        async value() {
            await this.reset();
        },
    },
    async mounted() {
        await this.reset();
    },
    methods: {
        async reset() {
            try {
                this.favor = await WS.request('favor_systems');
                this.options = await WS.request('system_list', { id: this.value });
                if (this.options.length > 0) {
                    this.search = this.options[0].name
                } else {
                    this.search = null
                }
            } catch (err) {
                alert(err);
            }
        },
        async refresh() {
            try {
                this.options = await WS.request('system_list', { id: this.value, name: this.search,
                                                                 populated_only: this.populatedOnly });
            } catch (err) {
                alert(err);
            }
        },
    }
}
</script>

<style scoped>
.selector-droplist {
    width: 400px;
}
.mydropdownitems {
    width: 400px;
    overflow-y: scroll;
    max-height: 70vh;
}
</style>
