<template>
<b-dropdown :text="valueText" :disabled="disabled" block >
  <b-dropdown-form>
    <b-form-group>
      <b-form-input v-model="search" size="sm" placeholder="поиск..." 
                    debounce="500" v-on:update="refresh"></b-form-input>
    </b-form-group>
  </b-dropdown-form>
  <div class="mydropdownitems">
    <b-dropdown-group header="Избранное" header-variant="text-light" v-if="favor.length > 0">
      <b-dropdown-item v-for="fv in favor" :key="fv.id" @click="$emit('input',fv.id)">
        <em>{{fv.name}}</em>
      </b-dropdown-item>
    </b-dropdown-group>
    <b-dropdown-divider v-if="favor.length > 0"></b-dropdown-divider>
    <b-dropdown-item v-for="opt in filteredOptions" :key="opt.id" @click="$emit('input', opt.id)">
      {{opt.name}}
    </b-dropdown-item>
  </div>
</b-dropdown>
</template>

<script>
import { WS } from  '@/misc.js';

export default {
    name: "FactionSelector",
    props: {
        value: Number,
        disabled: { type: Boolean, default: false },
        nofavor: { type: Boolean, default: false },
    },
    data: () => ({
        options: [],
        search: '',
        favor: [],
    }),
    computed: {
        filteredOptions() {
            return this.options.filter(item => {
                return (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
            });
        },
        valueText() {
            let opt = this.options.find(item => item.id == this.value);
            return opt ? opt.name : 'Неизвестный';
        }
    },
    watch: {
        async value() {
            await this.reset();
        },
    },
    async mounted() {
        await this.reset();
    },
    methods: {
        async reset() {
            try {
                if (!this.nofavor) {
                    this.favor = await WS.request('favor_factions');
                }
                this.options = await WS.request('faction_list', { id: this.value });
                if (this.options.length > 0) {
                    this.search = this.options[0].name
                } else {
                    this.search = null
                }
            } catch (err) {
                alert(err);
            }
        },
        async refresh() {
            try {
                this.options = await WS.request('faction_list', { name: this.search });
            } catch (err) {
                alert(err);
            }
        },
    }
}
</script>

<style scoped>
.selector-droplist {
    width: 500px;
}
.mydropdownitems {
    width: 500px;
    overflow-y: scroll;
    max-height: 70vh;
}
</style>
