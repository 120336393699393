import { render, staticRenderFns } from "./ModuleSearch.vue?vue&type=template&id=229cb1cf&"
import script from "./ModuleSearch.vue?vue&type=script&lang=js&"
export * from "./ModuleSearch.vue?vue&type=script&lang=js&"
import style0 from "./ModuleSearch.vue?vue&type=style&index=0&id=229cb1cf&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports