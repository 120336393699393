<template>
<div class="p-2">
  <b-row>
    <b-col cols="4">
      <b-form-group label="Откуда" >
        <div class="d-flex">
          <system-selector v-model="system1_id" @input="refresh1" class="flex-fill"></system-selector>
          <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
                     class="d-inline-block">
            <b-button :disabled="busy" class="mx-2" variant="primary" @click="refresh_profile">
              Где я?
            </b-button>
          </b-overlay>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="4">
      <b-form-group label="Станция">
        <b-form-select v-model="station1_id" v-bind:options="stations1" text-field="name" value-field="id">
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Радиус">
        <b-input type="number" v-model="range" @update="refresh1"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Предложение">
        <b-input type="number" v-model="min_stock"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  
  <b-row>
    <b-col cols="4">
      <b-form-group label="Куда">
        <!-- <b-form-select v-model="system2_id" v-bind:options="nearest_systems" @change="refresh2" -->
        <!--                value-field="id"> -->
        <!-- </b-form-select> -->

        
        <b-dropdown :text="system2Text" variant="outline-secondary" block menu-class="w-100">
          <b-dropdown-form @submit.stop.prevent="() => {}">
            <b-form-group label="Поиск" label-cols-md="auto" label-size="sm" 
                          class="mb-0" :description="searchDesc">
              <b-form-input v-model="system_search" type="search" size="sm" autocomplete="off"></b-form-input>
            </b-form-group>
          </b-dropdown-form>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="ddcontainer">
            <b-dropdown-item-button v-for="option in nearest_systems_filtered" :key="option.id"
                                    @click="onOptionClick(option)">
              {{ option.text }}
            </b-dropdown-item-button>
            <b-dropdown-text v-if="nearest_systems.length === 0">
              Список пуст
            </b-dropdown-text>
          </div>
        </b-dropdown>
        
        
      </b-form-group>
    </b-col>
    <b-col cols="4">
      <b-form-group label="Станция">
        <b-form-select v-model="station2_id" v-bind:options="stations2" text-field="name" value-field="id">
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Профит">
        <b-input type="number" v-model="min_profit"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Спрос">
        <b-input type="number" v-model="min_demand"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  
  <!-- <b-row align-v="end"> -->
  <!-- </b-row> -->
  <b-form-group>
    <b-button variant="outline-success" @click="doSearch">Искать</b-button>
  </b-form-group>
  
  <b-card no-body header="Туда" header-class="small">
    <b-table :items="forward_result" :fields="result_fields"
             head-variant="dark" small striped responsive hover bordered>
    </b-table>
  </b-card>

  <b-card no-body header="Обратно" header-class="small">
    <b-table :items="backward_result" :fields="result_fields"
             head-variant="dark" small striped responsive hover bordered>
    </b-table>
  </b-card>
  
</div>
</template>


<script>
import { WS } from '@/misc.js';
import SystemSelector from '@/components/SystemSelector.vue';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'TradeS2S',
    components: {
        SystemSelector,
    },
    data() {
        return {
            busy: false,
            range: 50,
            min_stock: 5000,
            min_demand: 5000,
            min_profit: 1000,
            station1_id: null,
            station2_id: null,
            system1_id: null,
            system2_id: null,
            system_search: '',
            nearest_systems: [],
            stations1: [],
            stations2: [],
            result: [],
            result_fields: [
                { key: 'name', label: 'Название', sortable: true },
                { key: 'category', label: 'Категория', sortable: true },
                { key: 'buy_price', label: 'Покупка', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'sell_price', label: 'Продажа', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'mean_price', label: 'Средняя',
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'diff', label: 'Профит', sortable: true,
                  class: "text-right", thStyle: {width:"100px"},
                },
                { key: 'stock', label: 'Предложение', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'demand', label: 'Спрос', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'updated', label: 'Обновлено', sortable: true,
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
        }
    },
    computed: {
        criteria() {
            return this.system_search.trim().toLowerCase()
        },
        nearest_systems_filtered() {
            return this.nearest_systems.filter(item => {
                return (this.criteria == "" || item.name.toLowerCase().indexOf(this.criteria) > -1);
            });
        },
        forward_result() {
            return this.result.filter(item => {
                return (item.direction == 'forward');
            });
        },
        backward_result() {
            return this.result.filter(item => {
                return (item.direction == 'backward');
            });
        },
        system2Text() {
            let opt = this.nearest_systems_filtered.find(item => item.id == this.system2_id);
            return opt ? opt.text : 'Неизвестно';
        }
    },
    async mounted() {
        try {
            let profile = await WS.request('user_profile');
            this.system1_id = profile.system_id;
            this.station1_id = profile.starport_id;
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_profile: ${err}`);
        } 
        await this.refresh1();
    },
    methods: {
        async refresh_profile() {
            this.busy = true;
            try {
                let profile = await WS.request('profile');
                this.system1_id = profile.system_id;
                this.station1_id = profile.starport_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(`profile: ${err}`);
            }
            this.busy = false;
            await this.refresh1();
        },
        async refresh1() {
            try {
                this.stations1 = await WS.request("system_stations", this.system1_id);
                if (this.station1_id) {
                    if (this.stations1.findIndex(item => item.id == this.station1_id) < 0) {
                        this.station1_id = null;
                    }
                }
                this.nearest_systems = await WS.request("systems_near", { system_id: this.system1_id,
                                                                          range: Number(this.range) } );
                this.nearest_systems.forEach((item) => {
                    item['text'] = `${item.name}  (${item.economy})`;
                });
                if (this.system2_id) {
                    if (this.nearest_systems.findIndex(item => item.id == this.system2_id) < 0) {
                        this.system2_id = null;
                        this.station2_id = null;
                    }
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh1: ${err}`);
            } 
        },
        async refresh2() {
            try {
                this.stations2 = await WS.request("system_stations", this.system2_id);
                if (this.station2_id) {
                    if (this.stations2.findIndex(item => item.id == this.station2_id) < 0) {
                        this.station2_id = null;
                    }
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh1: ${err}`);
            } 
        },
        async onOptionClick(option) {
            this.system2_id = option.id;
            this.system_search = '';
            await this.refresh2();
        },
        async doSearch() {
            try {
                this.result = await WS.request("trade_s2s", { station1: this.station1_id,
                                                              station2: this.station2_id,
                                                              min_stock: Number(this.min_stock),
                                                              min_demand: Number(this.min_demand),
                                                              min_profit: Number(this.min_profit),
                                                            });
            } catch (err) {
                this.$bvModal.msgBoxOk(`doSearch: ${err}`);
            } 
        },
    },

}
</script>

<style>

</style>
