<template>
<div>
  <b-form inline class="mb-2">
    <b-form-datepicker class="mx-2" v-model="date" locale="ru" hide-header placeholder="no date" size="sm"
                       :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                       @input="refresh" v-show="!tickMode">
    </b-form-datepicker>
    <b-form-checkbox size="sm" class="mx-2" v-model="tickMode" @input="refresh" switch> 
      по тику 
    </b-form-checkbox>
    <b-input-group prepend="Тик" class="mx-2" size="sm" v-show="tickMode">
      <b-input-group-append>
        <b-form-select v-model="tick_id" v-bind:options="ticks" text-field="tick_text" value-field="id" @input="refresh" size="sm"> </b-form-select>
      </b-input-group-append>
    </b-input-group>

    <!-- <b-form-checkbox class="mx-2" v-model="myonly" switch @input="refresh" size="sm"> -->
    <!--   Только собственные -->
    <!-- </b-form-checkbox> -->
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
          <template #first>
            <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Статус" class="mx-2" size="sm" v-show="!groupedMode">
      <b-input-group-append>
        <b-form-select v-model="mission_status" v-bind:options="mission_statuses" size="sm"
                       @input="refresh"> </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Тип" class="mx-2" size="sm" v-show="!groupedMode">
      <b-input-group-append>
        <b-form-select v-model="mission_type" v-bind:options="mission_types" size="sm"
                       @input="refresh"> </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Откого" class="mx-2" size="sm" v-show="!groupedMode">
      <b-input-group-append>
        <b-form-select v-model="mission_faction" v-bind:options="mission_summary.factions" size="sm">
          <template #first>
            <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Откуда" class="mx-2" size="sm" v-show="!groupedMode">
      <b-input-group-append>
        <b-form-select v-model="mission_system" v-bind:options="mission_summary.systems" size="sm">
          <template #first>
            <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    
    <b-form-checkbox size="sm" class="mx-2" v-model="groupedMode" @input="refresh" switch> режим группировки </b-form-checkbox>
  </b-form>
  
  <div v-if="groupedMode">
    <div class="accordion" role="tablist">
      <b-card no-body v-for="item in missions_grouped" :key="item.system_id" bg-variant="light">
        <b-card-header header-tag="header" role="tab">
          <b-button block v-b-toggle="`accordion-${item.system_id}`" variant="info">{{item.system}}</b-button>
        </b-card-header>
        <b-collapse :id="`accordion-${item.system_id}`" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text v-for="fc in item.factions" :key="fc.faction_id">
                Фракция 
                <span v-if="fc.faction_id">
                  <b-link :to="`/faction/${fc.faction_id}`">{{fc.faction}}</b-link>
                </span>
                <span v-else>{{fc.faction}}</span>
              <div v-if="fc.influence">
                Влияние {{(fc.influence > 0 ? ' улучшилось' : ' ухудшилось')}} на {{fc.influence}}
              </div>
              <p>Миссий выполнено: {{fc.count}}</p>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
  <div v-else>
    <b-table :items="missions_filtered" :fields="missions_fields" 
             bordered head-variant="dark" striped responsive hover small
             :tbody-tr-class="missionsRowClass" @row-clicked="onClick">
      <template #cell(expiry)="data">
        <div v-if="data.item.status == 'Принято'">
          {{data.value}}
        </div>
        <div v-else-if="tickMode">
          {{ data.item.completed | ts2time }}
        </div>
        <div v-else>
          {{data.item.status}}
        </div>
      </template>      
      <template #cell(faction)="data">
        <b-link :to="`/faction/${data.item.faction_id}`">{{data.item.faction}}</b-link>
      </template>      
      <template #cell(src_system)="data">
        <b-link :to="`/system/${data.item.src_system_id}`">{{data.item.src_system}}</b-link>
      </template>      
      <template #cell(dst_system)="data">
        <b-link :to="`/system/${data.item.dst_system_id}`">{{data.item.dst_system}}</b-link>
        <b-link :to="`/faction/${data.item.target_faction_id}`" class="float-right text-muted">
          {{data.item.target_faction}}
        </b-link>
      </template>      
      <!-- <template #cell(target_faction)="data"> -->
      <!--   <b-link :to="`/faction/${data.item.target_faction_id}`">{{data.item.target_faction}}</b-link> -->
      <!-- </template>       -->
      <template #row-details="row">
        <b-card>
          <div v-for="(rep,idx) in row.item.reputation" :key="idx">
            {{rep.faction}}: репутация {{(rep.reputation > 0 ? 'улучшилась' : 'ухудшилась')}} на {{rep.reputation}} эффект {{rep.effect}}
          </div>
          <div v-for="(inf,idx) in row.item.influence" :key="idx">
            <div v-if="inf.influence">
            Влияние {{inf.faction}} в системе {{inf.system}} {{(inf.influence > 0 ? 'улучшилась' : 'ухудшилась')}} на {{inf.influence}}
            </div>
          </div>
          <div v-if="row.item.detail">
            <div v-if="row.item.detail.TargetType">
              Цели {{row.item.detail.TargetType}} в количестве {{row.item.detail.KillCount}} шт.
            </div>
            <div v-if="row.item.detail.Commodity">
              Товар {{row.item.detail.Commodity}} в количестве {{row.item.detail.Count}}
            </div>
            <div v-if="row.item.detail.PassengerType">
              Пассажиры {{row.item.detail.PassengerType}} в количестве {{row.item.detail.PassengerCount}},
              <span v-if="row.item.detail.PassengerVIPs"> VIP </span>
              <span v-if="row.item.detail.PassengerWanted"> в розыске </span>
            </div>
          </div>
          <b-button variant="danger" @click="missionDelete(row.item.id)">Удалить миссию</b-button>
        </b-card>
      </template>
    </b-table>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CmdrMissions',
    inject: ['getUserInfo'],
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            tickMode: false,
            tick_id: null,
            // myonly: true,
            user_id: this.getUserInfo().id,
            users: [],
            groupedMode: false,
            mission_summary: {},
            mission_status: null,
            mission_statuses: [ { value:null, text:'Все' },
                                { value:'Accepted', text:'Принято' },
                                { value:'Completed', text:'Завершено' },
                                { value:'Abandoned', text:'Отменено' },
                                { value:'Failed', text:'Провалено' },
                              ],
            mission_type: null,
            mission_types: [{value:null, text:'Все'},
                            {value:'Altruism', text:'Донаты'},
                            {value:'Assassinate', text:'Убийство'},
                            {value:'Massacre', text:'Резня'},
                            {value:'Passenger', text:'Пассажиры'},
                            {value:'Delivery', text:'Доставка'},
                            {value:'Salvage', text:'Спасение'},
                            {value:'Scan', text:'Сканы'},
                            {value:'_other_', text:'Прочее'},
                           ],
            mission_faction: null,
            mission_system: null,
            missions: [],
            missions_fields: [
                // { key: 'accepted', label: 'Время', sortable: true, thStyle: {width:"150px"},
                //   formatter: value => {
                //       let tm = moment.unix(value);
                //       if (tm.isAfter(moment().startOf('date'))) {
                //           return tm.format("HH:mm");
                //       } else {
                //           return tm.fromNow(true);
                //           // return tm.format("DD.MM HH:mm")
                //       }
                //   }
                // },
                { key: 'expiry', label: 'Срок', sortable: true, thStyle: {width:"150px"},
                  formatter: value => {
                      let tm = moment.unix(value);
                      return tm.fromNow();
                  }
                },
                { key: 'username', label: 'Пилот', sortable: true, thStyle: {width:"150px"} },
                { key: 'name', label: 'Название', sortable: true, thStyle: {width:"600px"} },
                { key: 'faction', label: 'Откого', sortable: true, thStyle: {width:"350px"} },
                { key: 'src_system', label: 'Откуда', sortable: true },
                { key: 'dst_system', label: 'Куда', sortable: true, thStyle: {width:"350px"} },
                // { key: 'target_faction', label: 'Накого', sortable: true, thStyle: {width:"400px"} },
                // { key: 'status', label: 'Статус', sortable: true, thStyle: {width:"100px"} },
            ],
            missions_grouped: [],
        }
    },
    computed: {
        missions_filtered() {
            return this.missions.filter(item => {
                return ((this.mission_faction === null || (item.faction_id == this.mission_faction)) &&
                        (this.mission_system === null || item.src_system_id == this.mission_system));
            });
        },
    },
    async mounted() {
        this.$on('refresh', this.refresh);
        this.users = await WS.request('users');
    },
    methods: {
        async refresh() {
            try {
                this.ticks = await WS.request('ticks');
                this.ticks.forEach((item) => {
                  if (this.tick_id === null && moment.unix(item.tick_from).isBefore()) {
                      this.tick_id = item.id;
                  } 
                  let ts1 = item.tick_from !== null ? moment.unix(item.tick_from).format("DD.MM.YYYY HH:mm") : 'неизвестно';
                  let ts2 = item.tick_to !== null ? moment.unix(item.tick_to).format("DD.MM.YYYY HH:mm") : 'неизвестно';
                  Vue.set(item,'tick_text', `${ts1} - ${ts2}`);
                });
                this.mission_summary = await WS.request('mission_summary', { date: this.date });
                if (this.groupedMode) {
                  if (this.tickMode) {
                    this.missions_grouped = await WS.request('missions_grouped_bytick',
                                                             { tick: this.tick_id, user_id: this.user_id });
                  } else {
                    this.missions_grouped = await WS.request('missions_grouped',
                                                             { date: this.date, user_id: this.user_id });
                  }
                } else {
                  if (this.tickMode) {
                    this.missions = await WS.request('missions_bytick', { 
                      tick: this.tick_id, 
                      user_id: this.user_id,
                      mission_type: this.mission_type,
                      mission_status: this.mission_status,
                      faction: this.mission_faction,
                      system: this.mission_system,
                    });
                  } else {
                    this.missions = await WS.request('missions', {
                      date: this.date,
                      user_id: this.user_id,
                      mission_type: this.mission_type,
                      mission_status: this.mission_status,
                      faction: this.mission_faction,
                      system: this.mission_system,
                    });
                  }
                  this.missions.forEach((item) => {
                      Vue.set(item,'_showDetails', false);
                  });
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        onClick(item) {
            item._showDetails = !item._showDetails;
        },
        missionsRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status == 'Принято') return 'table-success'
            if (item.status == 'Отменено') return 'table-warning'
            if (item.status == 'Провалено') return 'table-danger'
        },
        async missionDelete(mission_id) {
          try {
                await WS.request('mission_delete', { id: mission_id });
                await this.refresh();
              } catch (err) {
                this.$bvModal.msgBoxOk(`missionDelete ${err}`);
            } 
        }
    },
    filters: {
      ts2time(value) {
        return moment.unix(value).format("HH:mm")
      }
    }
    
}
</script>

<style>
.systemstable {
    /* overflow-y: scroll; */
    max-height: calc(100vh - 300px);;
}

</style>
