<template>
<div class="p-2">

  <b-list-group>
    <b-list-group-item button v-for="item in ticks" :key="item.id" v-on:click="updateTick(item)">
      {{ item.tick|timestamp }}
    </b-list-group-item>
  </b-list-group> 

  <b-modal id="tick_update-modal" title="Тик">
    <b-form-group>
      <b-form-datepicker v-model="tick_date" locale="ru"></b-form-datepicker>
      <b-form-timepicker v-model="tick_time" locale="ru"></b-form-timepicker>
    </b-form-group>
    <template #modal-footer>
      <b-button variant="danger" @click="delTick">
        Удалить
      </b-button>
      <b-button variant="success" @click="saveTick">
        Изменить
      </b-button>
    </template>
  </b-modal>
 
</div>
</template>


<script>
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'TickTime',
    data() {
        return {
            ticks: [],
            tick_date: null,
            tick_time: null,
            tick_id: null,
        }
    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.ticks = await WS.request("tick_list");
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            } 
        },
        updateTick(item) {
          this.tick_id = item.id;
          this.tick_date = moment.unix(item.tick).format("YYYY-MM-DD");
          this.tick_time = moment.unix(item.tick).format("LT");
          this.$bvModal.show("tick_update-modal");
        },
        async saveTick() {
            try {
                let ts = moment(`${this.tick_date} ${this.tick_time}`).utc().unix();
                await WS.request("tick_update", { id: this.tick_id, tick: Number(ts) });
                await this.refresh();
                this.$bvModal.hide("tick_update-modal");
            } catch (err) {
                this.$bvModal.msgBoxOk(`saveTick: ${err}`);
            } 
        },
        async delTick() {
            try {
                await WS.request("tick_delete", { id: this.tick_id });
                await this.refresh();
                this.$bvModal.hide("tick_update-modal");
            } catch (err) {
                this.$bvModal.msgBoxOk(`delTick: ${err}`);
            } 
        },
    },
    filters: {
      timestamp(ts) {
            return moment.unix(ts).format("LLLL");
      },
    },
        

}
</script>

<style>

</style>
