<template>
<div>
  <b-form inline>
    <b-form-datepicker class="mx-2" v-model="date" locale="ru" hide-header placeholder="no date"
                       :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                       @input="refresh">
    </b-form-datepicker>
    <!-- <b-form-checkbox class="mx-2" v-model="myonly" switch @input="refresh"> -->
    <!--   Только собственные -->
    <!-- </b-form-checkbox> -->
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
          <template #first>
            <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Тип" class="mx-2" v-if="!groupedMode" size="sm">
      <b-input-group-append>
        <b-form-select v-model="trade_kind" v-bind:options="trade_kinds" size="sm"> </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Система" class="mx-2" v-if="!groupedMode" size="sm">
      <b-input-group-append>
        <b-form-select v-model="trade_system" v-bind:options="trade_systems" size="sm">
          <template #first>
            <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-form-checkbox class="mx-2" v-model="groupedMode" switch size="sm" @input="refresh">
      режим группировки
    </b-form-checkbox>
  </b-form>

  <div v-if="groupedMode">
    <b-table :items="trade_grouped" :fields="trade_grouped_fields" 
             bordered head-variant="dark" striped responsive hover
             @row-clicked="onClick">
      <template #cell(system)="row">
        <b-link :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
      </template>      
      <template #cell(station)="row">
        <b-link :to="`/system/${row.item.station_id}`">{{row.item.station}}</b-link>
        <small class="float-right text-muted">{{row.item.faction}}</small>
      </template>      
      <template #row-details="row">
        <b-card>
          <b-card-text>
            <p v-for="line,idx in row.item.detail" :key="idx" class="m-1">
              {{line}}
            </p>
          </b-card-text>
        </b-card>
      </template>
    </b-table>
  </div>
  <div v-else class="mt-2">
    <b-table :items="trade_filtered" :fields="trade_fields" 
             bordered head-variant="dark" striped responsive hover
             @row-clicked="onClick">
      <template #cell(system)="row">
        <b-link :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
      </template>      
      <template #cell(station)="row">
        <b-link :to="`/station/${row.item.station_id}`">{{row.item.station}}</b-link>
          <small class="float-right text-muted">{{row.item.faction}}</small>
      </template>      
      <template #cell(commodity)="row">
        {{row.item.commodity}}
        <div>
          <small class="float-right text-muted">{{row.item.category}}</small>
        </div>
      </template>
      <template #cell(buy_price)="row">
        {{row.item.buy_price | money2text}}
        <div v-show="row.item.buy_price" >
          <small class="float-right text-muted">{{row.item.total | money2text}}</small>
        </div>
      </template>
      <template #cell(sell_price)="row">
        {{row.item.sell_price | money2text}}
        <div v-show="row.item.sell_price" >
          <small class="float-right text-muted">{{row.item.total | money2text}}</small>
        </div>
      </template>
      <template #cell(profit)="row">
        <div v-show="row.item.sell_price">
          {{(row.item.sell_price - row.item.avg_price_paid) | money2text}}
          <div>
            <small class="float-right text-muted">
              {{(row.item.sell_price - row.item.avg_price_paid) * row.item.amount | money2text}}
            </small>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CmdrTrade',
    inject: ['getUserInfo'],
    components: {
    },
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            // myonly: true,
            user_id: this.getUserInfo().id,
            users: [],
            trade_system: null,
            trade_systems: [],
            trade_kind: null,
            trade_kinds: [{ value:null, text:'Все' },
                          { value:'MarketBuy', text:'Покупка' },
                          { value:'MarketSell', text:'Продажа' }
                         ],
            trade: [],
            trade_fields: [
                { key: 'username', label: 'Пилот', sortable: true, thStyle: {width:"150px"}, },
                { key: 'time_stamp', label: 'Время', sortable: true, thStyle: {width:"100px"},
                  formatter: value => {
                      return moment.unix(value).format("HH:mm");
                  }
                },
                { key: 'system', label: 'Система', sortable: true },
                { key: 'station', label: 'Станция', sortable: true },
                { key: 'commodity', label: 'Товар', sortable: true },
                { key: 'amount', label: 'Колич', thStyle: {width:"100px"}, class: "text-right" },
                { key: 'buy_price', label: 'Покупка', sortable: true,
                  thStyle: {width:"100px"}, class: "text-right",
                  formatter: value => {
                      if (value === null) return
                      return value.toLocaleString('ru-RU');
                  }
                },
                { key: 'sell_price', label: 'Продажа', sortable: true,
                  thStyle: {width:"100px"}, class: "text-right",
                  formatter: value => {
                      if (value === null) return
                      return value.toLocaleString('ru-RU');
                  }
                },
                { key: 'profit', label: 'Профит', sortable: true,
                  thStyle: {width:"100px"}, class: "text-right",
                  formatter: value => {
                      if (value === null) return
                      return value.toLocaleString('ru-RU');
                  }
                },
            ],
            groupedMode: false,
            trade_grouped: [],
            trade_grouped_fields: [
                { key: 'system', label: 'Система', sortable: true },
                { key: 'station', label: 'Станция', sortable: true },
                { key: 'amount', label: 'Колич', sortable: true, thStyle: {width:"100px"}, class: "text-right" },
                { key: 'buy', label: 'Покупка', sortable: true, thStyle: {width:"100px"}, class: "text-right",
                  formatter: value => {
                      if (value === null) return
                      return value.toLocaleString('ru-RU');
                  }
                },
                { key: 'sell', label: 'Продажа', sortable: true, thStyle: {width:"100px"}, class: "text-right",
                  formatter: value => {
                      if (value === null) return
                      return value.toLocaleString('ru-RU');
                  }
                },
                { key: 'profit', label: 'Профит', sortable: true, thStyle: {width:"100px"}, class: "text-right",
                  formatter: value => {
                      if (value === null) return
                      return value.toLocaleString('ru-RU');
                  }
                },
            ],
            
        }
    },
    computed: {
        trade_filtered() {
            return this.trade.filter(item => {
                return ((this.trade_kind === null || item.kind == this.trade_kind) &&
                        (this.trade_system === null || item.system == this.trade_system));
            });
        },
    },
    async mounted() {
        this.$on('refresh', this.refresh);
        this.users = await WS.request('users');
    },
    methods: {
        async refresh() {
            try {
                if (this.date) {
                    if (this.groupedMode) {
                        this.trade_grouped = await WS.request('trade_grouped', {
                            date: this.date,
                            user_id: this.user_id,
                        });
                        this.trade_grouped.forEach((item) => {
                            Vue.set(item,'_showDetails', false);
                        });
                        
                    } else {
                        this.trade = await WS.request('trade', {
                            date: this.date,
                            user_id: this.user_id,
                        });
                        this.trade_systems = []
                        this.trade.forEach((item) => {
                            if (!this.trade_systems.includes(item.system)) {
                                this.trade_systems.push(item.system);
                            }
                        });
                    }
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        onClick(item) {
            item._showDetails = !item._showDetails;
        },
    },
    filters: {
        money2text(value) {
            if (value === null) return
            return value.toLocaleString('ru-RU');
        }
    },
}
</script>

<style>
.systemstable {
    /* overflow-y: scroll; */
    max-height: calc(100vh - 250px);
}

</style>
